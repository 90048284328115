import { Component, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EDITOR_CONFIG from "../../../../../../assets/constants/editorConfig";
import { DatePipe } from "@angular/common";

@Component({
  selector: "VideoForm",
  templateUrl: "./video.component.html",
  styleUrls: [
    "./video.component.scss",
    "../../content-management.scss",
    "../../../local-back-office.scss",
    "../../../../private.scss",
  ],
})
export class VideoComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() language: string;
  @Input() type: string;
  @Input() option: string;
  @Input() id: number;
  @Output() files: EventEmitter<{ file: any; id: number; type: string }> =
    new EventEmitter<{ file: ""; id: -1; type: "" }>();

  public Editor = ClassicEditor;
  public editorConfig;

  currentFiles: Array<File> = [];

  default_line = {
    url: "",
    text: "",
  };

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.editorConfig = EDITOR_CONFIG;
  }

  ngOnInit(): void {
    if (this.pageVideoFields(this.id).value.length <= 0)
      this.addLine(this.id, this.default_line);
  }

  pageVideoFields(index: number): FormArray {
    return this.parentForm
      .get(`pages_${this.type}`)
      ["controls"][index].get("pageVideoFields") as FormArray;
  }
  addLine(i: number, field) {
    const newLine: FormGroup = this.formBuilder.group({
      url: [field.url],
      text: [field.text],
    });
    this.pageVideoFields(i).push(newLine);
  }

  removeLine(index: number, i: number) {
    this.pageVideoFields(index).removeAt(i);
  }
}
