<div *ngIf="isLoaded">
  <!-- Page content is an image -->
  <div *ngIf="PAGE_CONTENT.type == 'FULL_IMAGE'" style="height: 100vh">
    <img
      src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{
        PAGE_CONTENT.pageImageFields.imagePath
      }}"
      class="responsive_full_image"
    />
  </div>
  <!-- Page content is a full text -->
  <div *ngIf="PAGE_CONTENT.type == 'FULL_TEXT'" style="height: 100vh">
    <div class="my-2 mx-auto px-3">
      <innerHTML
        HTMLcontent="{{ PAGE_CONTENT.pageTextFields.text }}"
      ></innerHTML>
    </div>
  </div>
  <!-- Page content is a video -->
  <div *ngIf="PAGE_CONTENT.type == 'VIDEO'" style="height: 100vh">
    <div class="p-2">
      <carousel
        interval="0"
        isAnimated="true"
        [(activeSlide)]="activeSlideIndex"
      >
        <slide *ngFor="let videoField of PAGE_VIDEOS; let i = index">
          <innerHTML HTMLcontent="{{ videoField.text }}"></innerHTML>
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              loading="lazy"
              [src]="videoField.url"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </slide>
      </carousel>
      <div *ngIf="PAGE_VIDEOS?.length > 1" class="row justify-content-between">
        <div class="col-4 d-flex justify-content-start">
          <button
            type="button"
            [style.background]="colorHeader"
            [style.color]="isLightColor(colorHeader) ? '#000000' : '#ffffff'"
            class="btn btn-navigation btn-navigation-previous"
            (click)="navigateSlide('previous')"
          >
            ← Previous
          </button>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button
            type="button"
            [style.background]="colorHeader"
            [style.color]="isLightColor(colorHeader) ? '#000000' : '#ffffff'"
            class="btn btn-navigation btn-navigation-next"
            (click)="navigateSlide('next')"
          >
            Next →
          </button>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  </div>
  <!-- Page content is image and text -->
  <div
    *ngIf="PAGE_CONTENT.type == 'IMAGE_AND_TEXT'"
    class="my-2"
    style="height: 100vh"
  >
    <!-- Browse arrayMoreInformation to get measures and display it-->
    <div
      *ngFor="
        let line of PAGE_CONTENT.pageCompositeFields;
        let index = index;
        let odd = odd;
        let even = even
      "
      class="mx-1"
    >
      <div class="row justify-content-center mt-4">
        <img src="../../../assets/img/round.png" class="iconRound" />
      </div>
      <!-- Get odd measures to display text then image -->
      <div *ngIf="odd" class="row justify-content-right mt-4">
        <div class="col-9 text-right">
          <innerHTML HTMLcontent="{{ line.text }}"></innerHTML>
        </div>
        <div class="col-3">
          <img
            src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{ line.imagePath }}"
            class="iconText"
          />
        </div>
      </div>
      <!-- Get even measures to display image then text -->
      <div *ngIf="even" class="row justify-content-left mt-4">
        <div class="col-3">
          <img
            src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{ line.imagePath }}"
            class="iconText"
          />
        </div>
        <div class="col-9 text-left">
          <innerHTML HTMLcontent="{{ line.text }}"></innerHTML>
        </div>
      </div>
    </div>
  </div>

  <!-- Call to action -->
  <call-to-action
    [colorHeader]="colorHeader"
    [CTA_LINK]="CTA_LINK"
    [CTA_TYPE]="CTA_TYPE"
    [CTA_LIST]="CTA_LIST"
    [CTA_LABEL]="CTA_LABEL"
    [ROUTES]="ROUTES"
    [language]="currentLanguage"
    page="home"
  ></call-to-action>
</div>
