import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { RoutesService } from "src/app/core/services/routes.service";
import { ShareDataService } from "src/app/core/services/share-data.service";
import { ChartComponent } from "./chart/chart.component";
import { Subscription } from "rxjs-compat/Subscription";
import { SitesService } from "src/app/core/services/sites.service";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { ROUTES } from "src/app/core/classes/Routes";
import { Router } from "@angular/router";

@Component({
  selector: "aac-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(ChartComponent) chartComponent: ChartComponent;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  DASHBOARD_IS_ACTIVATED: boolean = null;

  SITE_NAME: string = "";
  SITE: any;

  DATA_STATE: string = "PENDING";
  DATA: any;
  SAVE_DATA: any;

  campaignDate = {
    weekStart: 0,
    weekEnd: 0,
  };

  PAGE_CONTENT: any;

  currentLanguage: string = "";
  colorHeader: any;
  CTA_LABEL: string = "";
  CTA_TYPE: string = "";
  CTA_LINK: string = "";
  CTA_LIST = [];

  isLoaded: boolean = false;

  private currentSubRegionNumberSubscription: Subscription;
  private currentRoutesSubscribeOnLangChange: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _httpClientService: HttpclientService,
    private _routesService: RoutesService,
    private _shareDataService: ShareDataService,
    private _sitesService: SitesService
  ) {
    //Get routes objects in RoutesService
    this._routesService.currentRoutes.subscribe((routes) => {
      this.ROUTES = routes;
      if (routes.PARAMS) {
        const site = routes.PARAMS["site"];
        site ? (this.SITE_NAME = site) : (this.SITE_NAME = "");
        //Get translations following language
        this.translate.get("map").subscribe((val) => {
          let isMapEnabled: boolean = false;
          let isMapActivated: boolean = false;
          this._sitesService.getSite(this.SITE_NAME).then((value) => {
            let SITE: any = value;
            isMapEnabled = SITE.OPTIONS.MAP_ENABLED == "true" ? true : false;
            isMapActivated = val["activated"];
            if (!isMapEnabled || !isMapActivated)
              this.DASHBOARD_IS_ACTIVATED = false;
            else if (isMapEnabled || isMapActivated)
              this.DASHBOARD_IS_ACTIVATED = true;
            else console.error("Cannot find map options");
          });

          this.SITE = {
            SITE_NAME: this.SITE_NAME,
          };

          this.getCampaignDate();
          this.getTranslation();
        });
      }
      this.currentRoutesSubscribeOnLangChange =
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.getTranslation();
        });
    });

    //Get epedemic data on S3 bucket - returns JSON
    this._httpClientService
      .getData(this.SITE_NAME.toUpperCase())
      .then((data) => {
        var bufferDATA = [];

        //The JSON data is an array with complexe object
        //Each object is a week with a lot of data about this week
        for (let i = 0; i < Object.keys(data).length; i++) {
          data[i]["weekStart"] = data[i]["weekStart"].replace(/-/g, "/");
          data[i]["weekEnd"] = data[i]["weekEnd"].replace(/-/g, "/");

          var weekStart = new Date(data[i]["weekStart"]).getTime();
          var weekEnd = new Date(data[i]["weekEnd"]).getTime();

          //We create two new variables that will help us when we select a region, in order to keep cases and partialSumLocationCases at there total
          //These are the variables that we will read on the template
          data[i]["casesForLocation"] = data[i]["cases"];
          data[i]["partialSumForLocationCases"] =
            data[i]["partialSumLocationCases"];

          //We keep only data that are in the range date of the campaign (campaignDate are date set by Local Admin)
          if (
            weekStart > this.campaignDate.weekStart &&
            weekEnd < this.campaignDate.weekEnd
          ) {
            bufferDATA.push(data[i]);
          }
        }

        this.DATA = JSON.parse(JSON.stringify(bufferDATA));

        //We execute the subscribe of the observable currentSubRegionNumber
        //When we select a region on the map, we change the range data to take only the region figures
        this.currentSubRegionNumberSubscription =
          this._shareDataService.currentSubRegionNumber.subscribe(
            (receivedSubRegionNumber) => {
              if (receivedSubRegionNumber) {
                if (receivedSubRegionNumber === "-1")
                  this.DATA = this.removeFilterDiseaseDataOnSubRegionNumber(
                    this.DATA
                  );
                else
                  this.DATA = this.filterDiseaseDataOnSubRegionNumber(
                    this.DATA,
                    receivedSubRegionNumber
                  );
                this.chartComponent.onChanges();
              }
            }
          );

        this.DATA_STATE = "READY";
      })
      .catch((err) => {
        this.DATA_STATE = "UNAVAILABLE";
        console.error("Impossible to retrieve epidemic data:", err);
      });
  }

  ngOnInit(): void {}

  getTranslation() {
    this.translate
      .get("language")
      .subscribe((val) => (this.currentLanguage = val));
    let promisesTranslate = [];
    promisesTranslate.push(this.translate.get(`pages`).toPromise()); //Get pages array  //hard code value

    Promise.all(promisesTranslate).then((value) => {
      if (value[0] === `pages`) {
        //hard code value
        console.error("Error on getting the translation");
      } else {
        this.PAGE_CONTENT = value[0].filter((value) => value.id === "home")[0];
        if (this.PAGE_CONTENT)
          if (this.PAGE_CONTENT.length < 1) this.navigate("home");
        this.isLoaded = true;

        this.CTA_TYPE = this.PAGE_CONTENT["callToAction"]["type"];
        this.CTA_LABEL = this.PAGE_CONTENT["callToAction"]["label"];
        switch (this.CTA_TYPE) {
          case "FILE":
            const fileName =
              this.PAGE_CONTENT["callToAction"]["callToActionDocument"][
                "documentPath"
              ];
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            if (extension === "pdf" || extension === "ics")
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/DATA/${extension}/${fileName}`;
            else if (listOfImagesExtensions.includes(extension))
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${fileName}`;
            break;
          case "EXTERNAL":
            this.CTA_LINK =
              this.PAGE_CONTENT["callToAction"]["callToActionExternalLink"][
                "url"
              ];
            break;
          case "SURVEY":
            this.CTA_LINK = `/survey/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionSurvey"]["survey"]}`;
            break;
          case "INTERNAL":
            if (
              this.PAGE_CONTENT["callToAction"]["callToActionPage"]["page"] ===
              "home"
            ) {
              this.CTA_LINK = `/home/${this.SITE_NAME}`;
            } else {
              this.CTA_LINK = `/more-information/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionPage"]["page"]}`;
            }
            break;
          case "MULTI_CTA":
            this.CTA_LIST =
              this.PAGE_CONTENT["callToAction"]["callToActionMultiCta"][
                "ctaList"
              ];
            break;
          default:
            console.error(`Error ! Type ${this.CTA_TYPE} is not allowed`);
        }
      }
      if (value[0][0]["color"]) {
        this.colorHeader = value[0][0]["color"];
      }
    });
  }

  /** Get the campaign date from the translations */
  getCampaignDate() {
    this.translate.get("map").subscribe((val) => {
      if (val != "map") {
        this.campaignDate.weekStart = val["startDateCampaign"];
        this.campaignDate.weekEnd = val["endDateCampaign"];
      }
    });
  }

  /**
   * If a region is selected, we get the data for this region for each week and put it in a variable
   * @param data the complete data for each region
   * @param subRegionNumber the number of the region
   * @returns return whole data, with the new value for the varable "*ForLocation*"
   */
  filterDiseaseDataOnSubRegionNumber(data, subRegionNumber) {
    for (let i = 0; i < data.length; i++) {
      var dataOnLocation = data[i]["subReports"].filter(
        (obj) =>
          parseInt(obj["location"]["subRegion"]["code"]) ===
          parseInt(subRegionNumber)
      );
      data[i]["casesForLocation"] = dataOnLocation[0]["cases"];
      data[i]["partialSumForLocationCases"] =
        dataOnLocation[0]["partialSumLocationCases"];
    }
    return data;
  }

  /**
   * If we remove all region selected, we remove all filters and reset the figures for on the variable concerned
   * @param data the complete data for each region
   * @returns return whole data reset
   */
  removeFilterDiseaseDataOnSubRegionNumber(data) {
    for (let i = 0; i < data.length; i++) {
      data[i]["casesForLocation"] = data[i]["cases"];
      data[i]["partialSumForLocationCases"] =
        data[i]["partialSumLocationCases"];
    }
    return data;
  }

  navigate(route: string) {
    this.router.navigate([`/${route}/${this.SITE_NAME.toLowerCase()}`]);
  }

  ngOnDestroy(): void {
    this.currentSubRegionNumberSubscription.unsubscribe();
    this.currentRoutesSubscribeOnLangChange.unsubscribe();
  }
}
