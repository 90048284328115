<form [formGroup]="parentForm">
  <div [formGroupName]="'pages_' + type">
    <div [formGroupName]="id">
      <div formArrayName="pageVideoFields" class="form-group">
        <span
          *ngFor="let field of pageVideoFields(id).controls; let i = index"
          [formGroupName]="i"
          class="col-12"
        >
          <div class="row">
            <div class="col-md-11 px-0">
              <div class="form-group">
                <p class="labelLike">URL {{ i + 1 }}</p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="URL of the video"
                  id="url{{ i }}"
                  formControlName="url"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11 px-0">
              <div class="form-group">
                <p class="labelLike">Text for video {{ i + 1 }}</p>
                <ckeditor
                  [editor]="Editor"
                  [config]="editorConfig"
                  id="text{{ i }}"
                  formControlName="text"
                ></ckeditor>
              </div>
            </div>
            <div class="col-md-1 align-self-center">
              <span
                class="material-icons red clickable"
                (click)="removeLine(id, i)"
              >
                remove_circle
              </span>
            </div>
          </div>
        </span>
        <div class="d-flex justify-content-center">
          <div class="col-6 justify-content-center add_line">
            <hr />
            <span
              class="material-icons blue add_line_button clickable"
              (click)="addLine(id, default_line)"
            >
              add_circle
            </span>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
