<span class="containerSectionPageEditionTitle">SELECT A PAGE</span>

<div class="d-flex justify-content-center my-3">
  <select class="col-6 form-control" (change)="onChangePages($event)">
    <option [value]="null">Choose a page to edit</option>
    <ng-container *ngIf="type === 'primary'">
      <option
        *ngFor="let page of parentForm.value.pages_primary; let i = index"
        [value]="page.id"
        [selected]="page.id === selectedPage"
      >
        {{ page.name }}
      </option>
    </ng-container>
    <ng-container *ngIf="type === 'secondary'">
      <option
        *ngFor="let page of parentForm.value.pages_secondary; let i = index"
        [value]="page.id"
        [selected]="page.id === selectedPage"
      >
        {{ page.name }}
      </option>
    </ng-container>
  </select>
</div>

<form [formGroup]="parentForm">
  <div [formGroupName]="'pages_' + type">
    <div class="form-group">
      <span
        *ngFor="let page of pages().controls; let i = index"
        [formGroupName]="i"
      >
        <div *ngIf="selectedPage === page.value.id">
          <div
            *ngIf="selectedPage != 'home'"
            class="row justify-content-center my-4"
          >
            <div class="col-8 p-0">
              <div class="row">
                <div
                  class="col-4 d-flex align-items-center justify-content-center p-0"
                >
                  <mat-slide-toggle
                    class="mat-slide-toggle-long"
                    formControlName="activated"
                    (change)="changePageId()"
                  ></mat-slide-toggle>
                </div>
                <div class="col-8">
                  <p class="labelLike">Page Name</p>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    id="name_{{ type }}_{{ i }}"
                    placeholder="Define the page name"
                    (change)="changePageId()"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="containerSectionPageEdition">
            <!-- Color edition -->
            <span class="labelSectionPageEdition">Choose a color</span>
            <div class="form-group mb-3">
              <div class="row">
                <div class="col-md-11 px-0">
                  <input
                    type="button"
                    class="form-control mb-2"
                    [(colorPicker)]="color"
                    (colorPickerClose)="onEventLog($event, i)"
                    [value]="color"
                    [cpPosition]="'bottom'"
                    [ngStyle]="{
                      'background-color': color,
                      color: isLightColor(color) ? '#000000' : '#ffffff'
                    }"
                  />
                </div>
              </div>
            </div>

            <!-- Layout edition -->
            <div class="labelSectionPageEdition mt-3 mb-2">Choose a layout</div>
            <div class="ml-3">
              <div class="row justify-content-center">
                <div>
                  <div class="row">
                    <div class="col-3 d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-control"
                        name="type"
                        formControlName="type"
                        id="layout1_{{ type }}_{{ i }}"
                        value="FULL_IMAGE"
                        (change)="addFields(i, $event.target.value, true)"
                      />
                      <label
                        for="layout1_{{ type }}_{{ i }}"
                        class="label-image"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Image Full Screen"
                        ><img
                          src="../../../../../../assets/img/PageTypeImage.png"
                      /></label>
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-control"
                        name="type"
                        formControlName="type"
                        id="layout2_{{ type }}_{{ i }}"
                        value="FULL_TEXT"
                        (change)="addFields(i, $event.target.value, true)"
                      />
                      <label
                        for="layout2_{{ type }}_{{ i }}"
                        class="label-image"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Full Text Screen"
                        ><img
                          src="../../../../../../assets/img/PageTypeFullText.png"
                      /></label>
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-control"
                        name="type"
                        formControlName="type"
                        id="layout3_{{ type }}_{{ i }}"
                        value="IMAGE_AND_TEXT"
                        (change)="addFields(i, $event.target.value, true)"
                      />
                      <label
                        for="layout3_{{ type }}_{{ i }}"
                        class="label-image"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Images and Texts Screen"
                        ><img
                          src="../../../../../../assets/img/PageTypeTextImage.png"
                      /></label>
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-control"
                        name="type"
                        formControlName="type"
                        id="layout4_{{ type }}_{{ i }}"
                        value="VIDEO"
                        (change)="addFields(i, $event.target.value, true)"
                      />
                      <label
                        for="layout4_{{ type }}_{{ i }}"
                        class="label-image"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Video"
                        ><img
                          src="../../../../../../assets/img/PageTypeVideo.png"
                      /></label>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="page.value.type === 'FULL_IMAGE'" class="row">
                <div class="col-md-12 px-0">
                  <FullImageForm
                    [parentForm]="parentForm"
                    [language]="language"
                    [type]="type"
                    [option]="page.value.type"
                    [id]="i"
                    (files)="addFiles($event)"
                  ></FullImageForm>
                </div>
              </div>
              <div *ngIf="page.value.type === 'FULL_TEXT'" class="row">
                <div class="col-md-12 px-0">
                  <FullTextForm
                    [parentForm]="parentForm"
                    [language]="language"
                    [type]="type"
                    [option]="page.value.type"
                    [id]="i"
                  ></FullTextForm>
                </div>
              </div>
              <div *ngIf="page.value.type === 'IMAGE_AND_TEXT'" class="row">
                <div class="col-md-12 px-0">
                  <ImageAndTextForm
                    [parentForm]="parentForm"
                    [language]="language"
                    [type]="type"
                    [option]="page.value.type"
                    [id]="i"
                    (files)="addFiles($event)"
                  >
                  </ImageAndTextForm>
                </div>
              </div>
              <div *ngIf="page.value.type === 'VIDEO'" class="row">
                <div class="col-md-12 px-0">
                  <VideoForm
                    [parentForm]="parentForm"
                    [language]="language"
                    [type]="type"
                    [option]="page.value.type"
                    [id]="i"
                  ></VideoForm>
                </div>
              </div>
            </div>

            <!-- Call to action edition -->
            <div class="labelSectionPageEdition mt-3 mb-2">
              Define a Call to Action for this page
            </div>
            <div formGroupName="callToAction" class="ml-3">
              <!-- Call to action text -->
              <div class="mb-2">
                <p class="labelLike">Button Text</p>
                <input
                  type="text"
                  class="form-control"
                  formControlName="label"
                  id="text_{{ type }}_{{ i }}"
                  placeholder="Label of the Button"
                />
              </div>

              <!-- Call to action type -->
              <p class="labelLike">Button Content</p>
              <select
                name="sel2"
                class="form-control mb-2"
                formControlName="type"
              >
                <option value="INTERNAL">Internal Page</option>
                <option value="FILE">
                  Images (.png or .jpeg), .pdf, Calendar file (.ics)
                </option>
                <option value="EXTERNAL">External Link</option>
                <option value="SURVEY">Form</option>
                <option value="MULTI_CTA">
                  Several Call-To-Action Buttons
                </option>
              </select>

              <!-- Call to action file -->
              <div *ngIf="page.value.callToAction.type === 'FILE'" class="mb-2">
                <div formGroupName="callToActionDocument">
                  <p>
                    <span class="labelLike">File </span
                    ><span class="aspect-indication">(Size Max. 2MB)</span>
                  </p>
                  <div class="row">
                    <div class="col-md-6 px-0">
                      <input
                        type="file"
                        class="custom-file-input"
                        accept="image/*, .pdf, .ics"
                        [id]="'formCallToActionDocument_documentPath_' + i"
                        (change)="onChangeFile($event, 'callToAction')"
                        formControlName="documentPath"
                      />
                      <label class="custom-file-label" for="image"
                        >Upload</label
                      >
                      <div
                        *ngIf="
                          page
                            .get('callToAction')
                            .get('callToActionDocument')
                            .get('documentPath').invalid
                        "
                        class="alert alert-danger"
                      >
                        <div
                          *ngIf="
                            page
                              .get('callToAction')
                              .get('callToActionDocument')
                              .get('documentPath').errors.invalidFileSize
                          "
                        >
                          This file is too large. It must be less than 2MB.
                        </div>
                        <div
                          *ngIf="
                            page
                              .get('callToAction')
                              .get('callToActionDocument')
                              .get('documentPath').errors.extension
                          "
                        >
                          File format not allowed. It must be an image, PDF or
                          Calendar.
                        </div>
                        <div
                          *ngIf="
                            page
                              .get('callToAction')
                              .get('callToActionDocument')
                              .get('documentPath').errors.unauthorizedFileName
                          "
                        >
                          File name not allowed. It must not contains any
                          special characters other than "-" and "_".
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                      <p
                        *ngIf="
                          page.value.callToAction.callToActionDocument
                            .documentPath;
                          else no_value_image
                        "
                        class="labelLike"
                      >
                        {{
                          page.value.callToAction.callToActionDocument
                            .documentPath
                        }}
                      </p>
                      <ng-template #no_value_image>
                        <p class="labelLike">No file</p>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Call to action link -->
              <div
                *ngIf="page.value.callToAction.type === 'EXTERNAL'"
                class="mb-2"
              >
                <div formGroupName="callToActionExternalLink" class="row">
                  <p class="labelLike">Exernal Link</p>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      id="url"
                      class="form-control"
                      formControlName="url"
                      placeholder="Enter an external link"
                      aria-describedby="external-link"
                    />
                  </div>
                </div>
              </div>

              <!-- Call to action survey -->
              <div
                *ngIf="page.value.callToAction.type === 'SURVEY'"
                class="mb-2"
              >
                <div formGroupName="callToActionSurvey">
                  <p class="labelLike">Form</p>
                  <div class="row">
                    <select
                      name="sel3"
                      class="form-control"
                      id="survey"
                      formControlName="survey"
                    >
                      <option [value]="null" disabled>Select form</option>
                      <option
                        *ngFor="let SURVEY of SURVEYS_LIST"
                        value="{{ SURVEY.UUID }}"
                      >
                        Form - {{ SURVEY.TITLE }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Call to action page -->
              <div
                *ngIf="page.value.callToAction.type === 'INTERNAL'"
                class="mb-2"
              >
                <div formGroupName="callToActionPage">
                  <p class="labelLike">Internal Page</p>
                  <div class="row">
                    <select
                      name="sel3"
                      class="form-control"
                      id="page"
                      formControlName="page"
                    >
                      <option [value]="null" disabled>
                        Select an internal page
                      </option>
                      <option
                        [value]="landing_page.value"
                        *ngFor="let landing_page of landing_page_array"
                      >
                        {{ landing_page.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Multi Call to action -->
              <div
                *ngIf="page.value.callToAction.type === 'MULTI_CTA'"
                class="mb-2"
              >
                <div
                  formGroupName="callToActionMultiCta"
                  class="callToActionMultiCta"
                >
                  <div class="multi_cta_container">
                    <div class="scroll_container">
                      <div
                        cdkDropListGroup
                        formGroupName="ctaList"
                        class="cta_container"
                      >
                        <!-- Add -- , 'background-image': 'url(' + CTA.value.logo + ')'-- In ngStyle to manage bg image for CTA -->
                        <div
                          cdkDropList
                          [cdkDropListEnterPredicate]="dropListEnterPredicate"
                          (cdkDropListDropped)="onDropListDropped()"
                        ></div>
                        <div
                          cdkDropList
                          class="cta_list_container"
                          *ngFor="
                            let CTA of getCtaListControl().controls;
                            let j = index
                          "
                          [cdkDropListEnterPredicate]="dropListEnterPredicate"
                          (cdkDropListDropped)="onDropListDropped()"
                          [ngClass]="{
                            'small-cta_list': CTA.value.size === 'Small'
                          }"
                          [ngStyle]="{
                            background: CTA.value.bgColor
                              ? CTA.value.bgColor
                              : 'rgb(228 228 228 / 90%)'
                          }"
                        >
                          <div
                            cdkDrag
                            [formGroupName]="j"
                            (cdkDragMoved)="onDragMoved($event)"
                            (click)="toggleCtaEdition($event, j)"
                            id="cta_item_{{ j }}"
                            class="cta_item"
                            [ngClass]="{
                              'small-cta': CTA.value.size === 'Small',
                              'medium-cta': CTA.value.size === 'Medium',
                              'large-cta': CTA.value.size === 'Large'
                            }"
                            [ngStyle]="{
                              background: CTA.value.bgColor
                                ? CTA.value.bgColor
                                : 'rgb(228 228 228 / 90%)'
                            }"
                          >
                            <div class="btn_edit_cta">
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 21.9375C0.751461 21.9371 0.513196 21.8383 0.337453 21.6625C0.161709 21.4868 0.0628307 21.2485 0.0625 21L0.0625 15.7737C0.0633995 15.5253 0.162212 15.2873 0.3375 15.1112L14.3888 1.06247C14.7045 0.7465 15.0794 0.495846 15.4921 0.324832C15.9047 0.153818 16.3471 0.0657959 16.7938 0.0657959C17.2404 0.0657959 17.6828 0.153818 18.0954 0.324832C18.5081 0.495846 18.883 0.7465 19.1987 1.06247L20.9375 2.80122C21.2535 3.11697 21.5041 3.49189 21.6751 3.90455C21.8462 4.31721 21.9342 4.75953 21.9342 5.20622C21.9342 5.65291 21.8462 6.09523 21.6751 6.50789C21.5041 6.92055 21.2535 7.29547 20.9375 7.61122L6.89 21.6625C6.803 21.7499 6.69956 21.8192 6.58563 21.8664C6.47171 21.9136 6.34956 21.9377 6.22625 21.9375H1ZM1.9375 16.1612V20.0625H5.8375L15.725 10.175L11.825 6.27497L1.9375 16.1612ZM17.0513 8.84997L19.615 6.28622C19.9007 5.99966 20.0611 5.6115 20.0611 5.20684C20.0611 4.80219 19.9007 4.41403 19.615 4.12747L17.8737 2.38497C17.5873 2.09895 17.1991 1.93831 16.7944 1.93831C16.3896 1.93831 16.0014 2.09895 15.715 2.38497L13.15 4.94997L17.0513 8.84997Z"
                                  fill="#525CA3"
                                />
                              </svg>
                              <div id="edit_nav_{{ j }}" class="edit_nav">
                                <a
                                  class="go_to_cta_edition"
                                  (click)="toggleCtaEdition($event, j)"
                                >
                                  Edit
                                </a>
                                <a
                                  class="copy_cta"
                                  (click)="copyCta($event, j)"
                                >
                                  Copy
                                </a>
                                <a
                                  *ngIf="currentCopyCtaStyle != null"
                                  class="paste_cta"
                                  (click)="pasteCta($event, j)"
                                >
                                  Paste
                                </a>
                                <a
                                  class="remove_cta"
                                  (click)="removeCta($event, j)"
                                >
                                  Remove
                                </a>
                              </div>
                            </div>
                            <div class="cta_logo">
                              <img
                                *ngIf="
                                  CTA.value.logo &&
                                  !CTA.value.currentUploadedLogo
                                "
                                [src]="
                                  ENVIRONMENT_PATH +
                                  'SITES/' +
                                  SITE_NAME +
                                  '-PREVIEW' +
                                  '/IMG/' +
                                  CTA.value.logo
                                "
                                width="50px"
                                height="50px"
                                alt="Logo"
                              />
                              <img
                                *ngIf="CTA.value.currentUploadedLogo"
                                id="output_image_{{ j }}"
                                width="50px"
                                height="50px"
                                alt="Logo"
                              />
                            </div>
                            <div
                              *ngIf="CTA.value.text !== ''"
                              class="cta_text_container"
                            >
                              <p
                                class="labelLike"
                                [ngStyle]="{
                                  color: CTA.value.textColor
                                    ? CTA.value.textColor
                                    : '#2b2b38'
                                }"
                                [innerHTML]="CTA.value.text"
                              ></p>
                            </div>
                            <div
                              class="btn_standalone_link"
                              [style.borderColor]="
                                CTA.value.textColor
                                  ? CTA.value.textColor
                                  : '#525CA3'
                              "
                            >
                              <svg
                                xmlns=" http://www.w3.org/2000/svg"
                                height="1em"
                                fill="#525CA3"
                                viewBox="0 0 448 512"
                                [ngStyle]="{
                                  fill: CTA.value.textColor
                                    ? CTA.value.textColor
                                    : '#525CA3'
                                }"
                              >
                                <path
                                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="add_cta_btn_container">
                      <div class="btn_add_cta" (click)="addEmptyCtaToForm()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 448 512"
                        >
                          <path
                            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                            fill="rgb(141 150 215 / 90%)"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</form>
