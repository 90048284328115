import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { GoogleAPIService } from "src/app/core/services/google-api.service";
import { Chart } from "chart.js";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { COLORS_ } from "src/app/core/classes/Colors";
import {
  JwtClaims,
  UserLoginService,
} from "src/app/core/services/user-login.service";
import { Observable } from "rxjs";
import { LocationsDependency } from "src/app/core/helpers/locations-dependency";
import { SitesService } from "src/app/core/services/sites.service";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { MatOption } from "@angular/material/core";
import { SITE } from "src/app/core/classes/Site";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { FileManagementService } from "src/app/core/services/file-management.service";

export interface filterClauses {
  startDate: number;
  endDate: number;
  sites: string[];
  location1: string[];
  location2: string[];
  beginWith: string;
  surveys: string[];
}
@Component({
  selector: "reporting-comp",
  templateUrl: "./reporting.component.html",
  styleUrls: ["./reporting.component.scss", "../../../../private/private.scss"],
})
export class ReportingComponent implements OnInit {
  Number: any;

  readonly SELECT_VALUE = LocationsDependency.SELECT_VALUE;

  @Input() TYPE?: string;
  @Input() SITE_NAME?: string;
  @Input() SITES?: string[];
  @Input() USER_NAME?: string;

  userToken$: Observable<JwtClaims>;

  /**ALERT_MESSAGE object to feed the alertMessage component child */
  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  SHOW_FILTERS_MODAL: boolean = true;
  CAMPAIGNS_LIST_IS_LOADED: boolean = false;
  DATA_IS_LOADED: boolean = false;
  DATA_IS_LOADING: boolean = false;
  message_error: string[] = [];

  scansPerDayCheckedDailyChart: boolean = true;
  cumulScansPeriodCheckedDailyChart: boolean = true;
  scansPerDayCheckedDailyChartEvent: boolean = true;
  cumulScansPeriodCheckedDailyChartEvent: boolean = true;

  CookAcceptPerDayCheckedDailyChart: boolean = true;
  cumulCookAcceptPeriodCheckedDailyChart: boolean = true;
  CookAcceptPerDayCheckedDailyChartEvent: boolean = true;
  cumulCookAcceptPeriodCheckedDailyChartEvent: boolean = true;

  DailyData: any;
  CumulatedData: any;
  CumulatedScanData: any;
  TrafficSourcesData: any;
  DailyEventData: any;
  CumulatedEventData: any;
  DailyScanData: string[] = [];

  myDailyScanChart: Chart;

  dailyDataScanChart = { labels: [], values: [] };
  cumulatedDataScanChart = { labels: [], values: [] };
  dailyDataCookieChart = { labels: [], values: [] };
  cumulatedDataCookieChart = { labels: [], values: [] };
  dailyDataEventChart = { labels: [], values: [] };
  cumulatedEventDataChart = { labels: [], values: [] };

  SITES_METADATA: any = [];
  SITES_TRANSLATIONS: any = [];
  listOfCampaignsOnEnv: SITE[] = [];
  listOfPages: any = [];
  callToActions: any = {
    ctaList: [],
    type: null,
    pageId: "",
    label: "",
    color: "",
    language: "",
    metrics: {
      totalEvents: {
        value: 0,
        percent: 0,
      },
      uniqueEvents: {
        value: 0,
        percent: 0,
      },
    },
    rate: {
      value: 0,
    },
  };

  sourceArray: string[] = [];
  mediumArray: string[] = [];
  filteredCampaigns: string[] = [];
  selectedCampaigns: string[] = [];
  tempSelectedCampaigns: string[] = [];
  selectedLocation1: string[] = [];
  selectedLocation2: string[] = [];

  searchInput: string = "";
  searchDate = [];
  dimensionFilterExpressions = [];
  bodyFilterClauses: filterClauses = {
    startDate: 0,
    endDate: 0,
    sites: [],
    location1: [],
    location2: [],
    beginWith: "",
    surveys: [],
  };
  lastRefresh: Date = new Date();
  campaignDateAnalytics: string = "";

  locations1Links: Map<string, string[]>;
  locations2Links: Map<string, string[]>;

  listOfSurveys = [];
  hasSurveys: boolean = false;

  BounceRate: string = "0";
  AverageSpentTime: string = "0";
  SelectedQRCodes: string = "0";
  TotalSelectedQRCodes: string = "0";
  ScansNumber: string = "0";
  CookiesAcceptedNumber: string = "0";
  CookiesRefusedRate: string = "0";
  CookiesAcceptedRate: string = "0";
  TotalCookiesAccepted: string = "0";
  UniqueUsers: string = "0";
  TotalUniqueUsers: string = "0";
  StayingUsers: string = "0";
  StayingScans: string = "0";
  ClickUniqueEvents: string = "0";
  ClickTotalEvents: string = "0";
  AnswerTotal: string = "0";
  RetentionRateLink1: string = "0";
  ConvertionRateLink2: string = "0";
  ConvertionRateLink3: string = "0";

  top10array = [];
  sitesArray: Array<any> = [];
  listOfSourceMediumNotScanned = [];
  eventsArray = [];
  displayedEventsArray = [];

  bsConfig: Partial<BsDatepickerConfig>;
  maxDate: Date;
  bsRangeValue: Date[] = [];

  clientUUID = null;

  constructor(
    private router: Router,
    private changeDetection: ChangeDetectorRef,
    private _GoogleAPIService: GoogleAPIService,
    private _userLoginService: UserLoginService,
    private _sitesService: SitesService,
    private _httpService: HttpclientService,
    private _fileManagementService: FileManagementService
  ) {
    this.userToken$ = this._userLoginService.getTokenJwtClaims();
    //We store Prototype.Number in a variable in order to reuse it in Template
    this.Number = Number;

    //Get the id of the user account  in order to generate unique file report for different user
    //Could be 'event_id' instead of 'client_id' for for multiple connexions to a user account
    this._userLoginService.getSession().then((val) => {
      this.clientUUID = val["accessToken"].payload.sub;
    });
  }

  ngOnInit() {
    this.filteredCampaigns = [];
    this.selectedCampaigns = [];
    this.tempSelectedCampaigns = [];
    this.selectedLocation1 = [];
    this.selectedLocation2 = [];

    this.resetAllFigures();

    if (this.TYPE === "LOCAL") {
      this.SHOW_FILTERS_MODAL = false;

      //Get list of sites of the environement
      this._sitesService.getSite(this.SITE_NAME).then(async (value) => {
        console.info("Value Sites: ", value);

        //Set campaign information field
        let SITE = JSON.parse(JSON.stringify(value));
        this.campaignDateAnalytics = SITE.MISC.CAMPAIGN_DATE_ANALYTICS;
        //Set the variables needed for other functions
        this.SITES = [].concat(value["SITE"]);
        this.SITES_METADATA = [].concat(value);
        this.listOfCampaignsOnEnv = this.SITES_METADATA;

        //We add the env. sites in the dimension filter clauses in order to get data only for the env. sites
        this.selectedCampaigns = [...this.SITES];
        this.filteredCampaigns = [...this.SITES];
        this.bodyFilterClauses.sites = [...this.selectedCampaigns];
        const filterExpressions = this.SITES.map((site) => site.toUpperCase());
        this.dimensionFilterExpressions.push({
          filter: {
            fieldName: "sessionCampaignName",
            inListFilter: {
              values: filterExpressions,
            },
          },
        });
        this.selectedCampaigns.push(this.SELECT_VALUE);

        // we add a default filer: campaign
        this.selectedCampaigns.push(...this.SITE_NAME);
        this.tempSelectedCampaigns = [...this.selectedCampaigns];
        this.CAMPAIGNS_LIST_IS_LOADED = true;

        this.bodyFilterClauses.sites = [this.SITE_NAME];
        this.dimensionFilterExpressions.push({
          filter: {
            fieldName: "sessionCampaignName",
            stringFilter: {
              matchType: "EXACT",
              value: this.SITE_NAME,
            },
          },
        });
        await this.ngOnInitCommonWork();
      });
    } else if (this.TYPE === "GLOBAL") {
      // Get list of sites of the environment
      this._sitesService.getSites().then(async (value) => {
        console.info("Value Sites: ", value);

        //We remove sites ending by "-PREVIEW" and get only the site name
        this.SITES = value["sites"]
          .filter((site) => !site["SITE"].includes("-PREVIEW"))
          .map((site) => site["SITE"].toUpperCase())
          .sort();
        this.SITES_METADATA = value["sites"].filter(
          (site) => !site["SITE"].includes("-PREVIEW")
        );
        this.listOfCampaignsOnEnv = value["sites"].filter(
          (site) => !site["SITE"].includes("-PREVIEW")
        );

        //We add the env. sites in the dimension filter clauses in order to get data only for the env. sites
        this.selectedCampaigns = [...this.SITES];
        this.filteredCampaigns = [...this.SITES];
        this.bodyFilterClauses.sites = [...this.selectedCampaigns];
        const filterExpressions = this.SITES.map((site) => site.toUpperCase());
        this.dimensionFilterExpressions.push({
          filter: {
            fieldName: "sessionCampaignName",
            inListFilter: {
              values: filterExpressions,
            },
          },
        });
        this.selectedCampaigns.push(this.SELECT_VALUE);
        this.tempSelectedCampaigns = [...this.selectedCampaigns];
        this.CAMPAIGNS_LIST_IS_LOADED = true;

        await this.ngOnInitCommonWork();
      });
    } else if (this.TYPE == "ADMIN") {
      console.info("Value Sites: ", this.SITES);
      this.USER_NAME = this.USER_NAME.toUpperCase();
      let tempSites = this.SITES;
      this.SITES = tempSites
        .filter((site) => !site["SITE"].includes("-PREVIEW"))
        .map((site) => site["SITE"].toUpperCase())
        .sort();
      this.SITES_METADATA = tempSites.filter(
        (site) => !site["SITE"].includes("-PREVIEW")
      );
      this.listOfCampaignsOnEnv = this.SITES_METADATA;

      //We add the env. sites in the dimension filter clauses in order to get data only for the env. sites
      this.selectedCampaigns = [...this.SITES];
      this.filteredCampaigns = [...this.SITES];
      this.bodyFilterClauses.sites = [...this.selectedCampaigns];
      const filterExpressions = this.SITES.map((site) => site.toUpperCase());
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionCampaignName",
          inListFilter: {
            values: filterExpressions,
          },
        },
      });
      this.selectedCampaigns.push(this.SELECT_VALUE);
      this.tempSelectedCampaigns = [...this.selectedCampaigns];
      this.CAMPAIGNS_LIST_IS_LOADED = true;

      this.ngOnInitCommonWork();
    }
  }

  //function used to improve code readability
  private async ngOnInitCommonWork() {
    //return list of surveys from call to actions
    const getListOfSurveys = (callToActions) => {
      let surveys = [];
      for (const callToAction of callToActions) {
        if (callToAction != undefined && callToAction.type === "SURVEY")
          surveys.push(callToAction.callToActionSurvey.survey);
      }
      return surveys;
    };

    //return list of call to action from pages
    const getListOfCallToAction = (pages) => {
      return pages.map((page) => page["callToAction"]);
    };

    //return list of pages from translation
    const getListOfPages = (translation) => {
      return [...translation["pages"]];
    };

    //Get all translations for all sites and all languages and get list of surveys
    await Promise.all(this.getTranslations(this.listOfCampaignsOnEnv)).then(
      (translations) => {
        this.SITES_TRANSLATIONS = translations;
        for (const translation of translations) {
          if (translation["pages"].length > 0) {
            //for each translations push the list of surveys
            this.listOfSurveys.push(
              getListOfSurveys(
                getListOfCallToAction(getListOfPages(translation))
              )
            );
            this.listOfSurveys = [].concat(...this.listOfSurveys);

            for (const page of translation["pages"]) {
              this.listOfPages.push({
                key: `${translation.language}_${page["id"]}`,
                value: `${translation.language} - ${page["name"]}`,
              });
            }
          }
        }
      }
    );

    //set bodyFilterClauses.surveys with list of surveys
    this.bodyFilterClauses.surveys = this.arrayUnique(this.listOfSurveys);

    //Configuration for calendar
    this.bsConfig = Object.assign(
      {},
      {
        adaptivePosition: true,
        isAnimated: true,
        containerClass: "theme-sanofi",
        dateInputFormat: "YYYY-MM-DD",
      }
    );

    /** Set the date */
    this.maxDate = new Date();

    this.bsRangeValue.push(
      new Date(new Date().setDate(new Date().getDate() - 30))
    );
    this.bsRangeValue.push(new Date(new Date().setDate(new Date().getDate())));

    this.searchDate[0] = this.formatDate(this.bsRangeValue[0]);
    this.searchDate[1] = this.formatDate(this.bsRangeValue[1]);

    //We push selected date into bodyFilterClauses array substract 12hours offset timezone
    this.bodyFilterClauses.startDate =
      new Date(this.bsRangeValue[0]).getTime() + 12 * 3600 * 1000;
    this.bodyFilterClauses.endDate =
      new Date(this.bsRangeValue[1]).getTime() + 12 * 3600 * 1000;

    if (this.TYPE === "LOCAL") {
      this.getData(true);
    }
  }

  getTranslations(listOfCampaigns) {
    const redux = (array) =>
      array.map((o) =>
        ["SITE", "LANGUAGES"].reduce((acc, curr) => {
          acc[curr] = o[curr];
          return acc;
        }, {})
      );

    let translationsPromises = [];
    for (const campaign of redux(listOfCampaigns)) {
      for (const language of campaign.LANGUAGES) {
        translationsPromises.push(
          this._httpService.getTranslation(campaign.SITE, language)
        );
      }
    }
    return translationsPromises;
  }

  /** Get data for reporting */
  public getData(isNgOnInit: boolean) {
    this.DATA_IS_LOADING = true;
    var promises = [];

    //In order to get Google Analytics data for old websites (COPAC and WATERFORD)
    //We need to add other filters for these two websites
    if (this.selectedCampaigns.includes("ELEFIGHT_COVID_COPAC")) {
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionCampaignName",
          stringFilter: {
            matchType: "EXACT",
            value: "colombiaperoucovid",
          },
        },
      });
    }
    if (this.selectedCampaigns.includes("ELEFIGHT_COVID_WATERFORD")) {
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "hostName",
          stringFilter: {
            matchType: "EXACT",
            value: "d393wm5fe9dh3z.cloudfront.net",
          },
        },
      });
    }

    this.dimensionFilterExpressions.push({
      notExpression: {
        filter: {
          fieldName: "sessionSourceMedium",
          stringFilter: {
            matchType: "EXACT",
            value: "(direct) / (none)",
          },
        },
      },
    });
    this.dimensionFilterExpressions.push({
      notExpression: {
        filter: {
          fieldName: "sessionSourceMedium",
          stringFilter: {
            matchType: "EXACT",
            value: "QR Code Custom / QR Code Custom",
          },
        },
      },
    });

    //Based on filters selected by users, we create a second array without campaign, source nor medium
    const typeOfDimensionNameForOption = ["sessionSource", "sessionMedium"];
    var dimensionFilterSelectOption = JSON.parse(
      JSON.stringify(this.dimensionFilterExpressions)
    );
    console.log(
      "dimensionFilterSelectOption before : ",
      JSON.parse(JSON.stringify(dimensionFilterSelectOption))
    );
    dimensionFilterSelectOption = dimensionFilterSelectOption.reduce(
      (acc, expression) => {
        if (
          !(
            expression.hasOwnProperty("filter") &&
            expression.filter.hasOwnProperty("fieldName") &&
            typeOfDimensionNameForOption.indexOf(
              expression.filter.fieldName
            ) !== -1
          )
        ) {
          acc.push(expression);
        }
        return acc;
      },
      []
    );
    console.log(
      "dimensionFilterSelectOption after : ",
      JSON.parse(JSON.stringify(dimensionFilterSelectOption))
    );

    console.log(this.selectedCampaigns);
    console.log(this.SITES);

    console.info(
      "dimensionFilter sent to GA API : ",
      this.dimensionFilterExpressions
    );
    console.info(
      "dimensionFilterSelectOption sent to GA API : ",
      dimensionFilterSelectOption
    );
    //0:22 - Call Google Anaytics API (elefight-reporting)
    promises.push(
      this._GoogleAPIService
        .getData(
          {
            startDate: this.searchDate[0],
            endDate: this.searchDate[1],
          },
          this.dimensionFilterExpressions,
          /* {
                    andGroup: {
                        expressions: this.dimensionFilterExpressions,
                    },
                } */
          dimensionFilterSelectOption,
          this.TYPE === "GLOBAL"
            ? "GLOBAL#SITE-BASE"
            : `LOCAL#${this.SITE_NAME}`,
          this.TYPE === "GLOBAL" ? this.selectedCampaigns : [this.SITE_NAME]
        )
        .catch((err) => this.callbackHTTPClient(err, 0))
    );

    console.info(
      "bodyFilterClauses sent to Elefight API : ",
      this.bodyFilterClauses
    );
    //24 - Call API to get reporting data (answers and qr codes) (elefight-site-report)
    promises.push(
      this._GoogleAPIService
        .getReportingData(this.bodyFilterClauses)
        .catch((err) => this.callbackHTTPClient(err.message))
    );

    //Once each calls are done, we transform data
    Promise.all(promises)
      .then((res) => {
        var valuesGoogleApi = [];
        var valuesElefightApi = [];

        console.info("Result from APIs ", res);

        //We store results from both APIs in specific variable
        if (res[0]) {
          valuesGoogleApi = [...res[0]];
        }
        if (res[1]) {
          valuesElefightApi = [...res[1]];
        }

        console.info("valuesGoogleApi = ", valuesGoogleApi);
        console.info("valuesElefightApi = ", valuesElefightApi);

        //Reset listOfSourceMedium
        var listOfSourceMedium = {
          listOfSourceMedium_QRCODES: [],
          listOfSourceMedium_GA: [],
        };

        if (valuesGoogleApi) {
          //We test each object in the array. Each object match to a sepcific uses
          if (
            valuesGoogleApi[0] != undefined &&
            this.testResponseAPI(valuesGoogleApi[0])
          ) {
            this.DailyData = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[0]))
            );
            this.dailyDataCookieChart = this.transformForChart(
              JSON.parse(JSON.stringify(this.DailyData))
            );
            this.CumulatedData = this.GenerateCumulatedData(
              JSON.parse(JSON.stringify(this.DailyData))
            );
            this.cumulatedDataCookieChart = this.transformForChart(
              JSON.parse(JSON.stringify(this.CumulatedData))
            );
            this.CookiesAcceptedNumber = this.DailyData["totals"];
          } else {
            console.error("No data in response 0");
            this.CookiesAcceptedNumber = "0";
            this.dailyDataCookieChart["labels"].length = 0;
            this.dailyDataCookieChart["values"].length = 0;
            this.cumulatedDataCookieChart["labels"].length = 0;
            this.cumulatedDataCookieChart["values"].length = 0;
          }
          if (
            valuesGoogleApi[1] != undefined &&
            this.testResponseAPI(valuesGoogleApi[1])
          ) {
            this.TotalCookiesAccepted =
              valuesGoogleApi[1]["totals"][0]["metricValues"][0]["value"];
          } else {
            console.error("No data in response 1");
          }
          if (
            valuesGoogleApi[3] != undefined &&
            this.testResponseAPI(valuesGoogleApi[3])
          ) {
            this.TotalUniqueUsers =
              valuesGoogleApi[3]["totals"][0]["metricValues"][0]["value"];
          } else {
            console.error("No data in response 3");
          }
          let campaignMediums = [];
          if (
            valuesGoogleApi[4] != undefined &&
            this.testResponseAPI(valuesGoogleApi[4])
          ) {
            var data = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[4]))
            );
            campaignMediums = data["rows"].map((row) => row.dimensions);
          } else {
            console.error("No data in response 4");
            this.mediumArray.length = 0;
          }
          let campaignSources = [];
          if (
            valuesGoogleApi[6] != undefined &&
            this.testResponseAPI(valuesGoogleApi[6])
          ) {
            var data = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[6]))
            );
            campaignSources = data["rows"].map((row) => row.dimensions);
          } else {
            console.error("No data in response 6");
            this.sourceArray.length = 0;
          }
          if (
            valuesGoogleApi[7] != undefined &&
            this.testResponseAPI(valuesGoogleApi[7])
          ) {
            this.TrafficSourcesData = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[7]))
            );
          } else {
            console.error("No data in response 7");
            this.sourceArray = [];
            this.mediumArray = [];
          }
          if (
            valuesGoogleApi[8] != undefined &&
            this.testResponseAPI(valuesGoogleApi[8])
          ) {
            this.AverageSpentTime = this.secondsToHms(
              valuesGoogleApi[8]["totals"][0]["metricValues"][0]["value"]
            );
          } else {
            console.error("No data in response 8");
            this.AverageSpentTime = "0";
          }
          if (
            valuesGoogleApi[9] != undefined &&
            this.testResponseAPI(valuesGoogleApi[9])
          ) {
            var number =
              valuesGoogleApi[9]["totals"][0]["metricValues"][0]["value"];
            this.BounceRate = parseFloat(number.replace(/,/g, ".")).toFixed(2);
          } else {
            console.error("No data in response 9");
            this.BounceRate = "0";
          }
          if (
            valuesGoogleApi[10] != undefined &&
            this.testResponseAPI(valuesGoogleApi[10])
          ) {
            this.top10array.length = 0;
            var data = this.SimplifyReturnGoogleAPI_TOP(
              JSON.parse(JSON.stringify(valuesGoogleApi[10]))
            );

            for (let index = 0; index < data["rows"].length; index++) {
              const element = data["rows"][index];

              element.dimension = element.dimension.trim();
              let qrCodeInArrayIndex = this.top10array.findIndex(
                (item) => item.dimension === element.dimension
              );
              let qrCodeInArray =
                qrCodeInArrayIndex !== -1
                  ? this.top10array[qrCodeInArrayIndex]
                  : null;
              if (qrCodeInArray != null) {
                qrCodeInArray.metrics.averageSpentTime =
                  element.metrics.averageSpentTime;
                qrCodeInArray.metrics.bounceRate = element.metrics.bounceRate;
                qrCodeInArray.metrics.newUsers = element.metrics.newUsers;
                qrCodeInArray.metrics.returningUsers =
                  element.metrics.returningUsers;
                qrCodeInArray.metrics.scans = element.metrics.scans;
                qrCodeInArray.metrics.uniqueUsers = element.metrics.uniqueUsers;
              } else {
                this.top10array.push(element);
              }
            }
          } else {
            console.error("No data in response 10");
            this.top10array.length = 0;
          }
          if (
            valuesGoogleApi[11] != undefined &&
            this.testResponseAPI(valuesGoogleApi[11])
          ) {
            var data = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[11]))
            );
            this.sitesArray = JSON.parse(JSON.stringify(data["rows"]));
            var result = [];

            this.sitesArray.forEach(function (a) {
              if (!this[a.dimensions]) {
                this[a.dimensions] = { dimensions: a.dimensions, metrics: 0 };
                result.push(this[a.dimensions]);
              }
              this[a.dimensions].metrics += a.metrics;
            }, Object.create(null));
            this.sitesArray = result;
          } else {
            console.error("No data in response 11");
            this.sitesArray.length = 0;
          }
          if (
            valuesGoogleApi[2] != undefined &&
            this.testResponseAPI(valuesGoogleApi[2])
          ) {
            this.UniqueUsers =
              valuesGoogleApi[2]["totals"][0]["metricValues"][0]["value"];
            var bounceRateInt = parseFloat(this.BounceRate.replace(",", "."));
            this.StayingUsers = Math.round(
              parseInt(this.UniqueUsers, 10) * (1 - bounceRateInt / 100)
            ).toString();
            this.StayingScans = Math.round(
              parseInt(this.CookiesAcceptedNumber, 10) *
                (1 - bounceRateInt / 100)
            ).toString();
            this.RetentionRateLink1 = ((1 - bounceRateInt / 100) * 100)
              .toFixed(2)
              .toString();
          } else {
            console.error("No data in response 2");
            this.UniqueUsers = "0";
            this.StayingUsers = "0";
            this.StayingScans = "0";
            this.RetentionRateLink1 = "0";
            this.ConvertionRateLink2 = "0";
          }
          if (
            valuesGoogleApi[12] != undefined &&
            this.testResponseAPI(valuesGoogleApi[12])
          ) {
            this.eventsArray.length = 0;
            this.displayedEventsArray.length = 0;

            let eventsMultiCallToAction = {
              totalEvents: 0,
              uniqueEvent: 0,
            };
            let totalEventsCallToAction = {
              "call-to-action": {
                totalEvents: 0,
                uniqueEvent: 0,
              },
            };

            for (
              let index = 0;
              index < valuesGoogleApi[12]["rows"].length;
              index++
            ) {
              let element = valuesGoogleApi[12]["rows"][index];
              const [eventType, id, pageId, language] =
                element["dimensionValues"][1]["value"].split("_");
              if (eventType.startsWith("multi-call-to-action")) {
                eventsMultiCallToAction.totalEvents += Number(
                  element.metricValues[0]["value"]
                );
                eventsMultiCallToAction.uniqueEvent += Number(
                  element.metricValues[1]["value"]
                );
                if (
                  totalEventsCallToAction[
                    `${eventType}_${language}_${pageId}`
                  ] === undefined
                ) {
                  totalEventsCallToAction[
                    `${eventType}_${language}_${pageId}`
                  ] = {
                    totalEvents: Number(element.metricValues[0]["value"]),
                    uniqueEvent: Number(element.metricValues[1]["value"]),
                  };
                } else {
                  totalEventsCallToAction[
                    `${eventType}_${language}_${pageId}`
                  ].totalEvents += Number(element.metricValues[0]["value"]);
                  totalEventsCallToAction[
                    `${eventType}_${language}_${pageId}`
                  ].uniqueEvent += Number(element.metricValues[1]["value"]);
                }
              } else if (eventType.startsWith("call-to-action")) {
                if (totalEventsCallToAction["call-to-action"] === undefined) {
                  totalEventsCallToAction["call-to-action"] = {
                    totalEvents: Number(element.metricValues[0]["value"]),
                    uniqueEvent: Number(element.metricValues[1]["value"]),
                  };
                } else {
                  totalEventsCallToAction["call-to-action"].totalEvents +=
                    Number(element.metricValues[0]["value"]);
                  totalEventsCallToAction["call-to-action"].uniqueEvent +=
                    Number(element.metricValues[1]["value"]);
                }
              } else {
                totalEventsCallToAction["call-to-action"].totalEvents += 0;
                totalEventsCallToAction["call-to-action"].uniqueEvent += 0;
              }

              let eventsInArrayIndex = this.eventsArray.findIndex(
                (item) =>
                  item.dimensions === element.dimensionValues[1]["value"]
              );
              let eventsInArray =
                eventsInArrayIndex !== -1
                  ? this.eventsArray[eventsInArrayIndex]
                  : null;
              if (eventsInArray != null) {
                eventsInArray.metrics.totalEvents = {
                  value:
                    Number(eventsInArray.metrics.totalEvents.value) +
                    Number(element.metricValues[0]["value"]),
                  percent: 0,
                };
                eventsInArray.metrics.uniqueEvents = {
                  value:
                    Number(eventsInArray.metrics.uniqueEvents.value) +
                    Number(element.metricValues[1]["value"]),
                  percent: 0,
                };
              } else {
                this.eventsArray.push({
                  dimensions: element.dimensionValues[1]["value"],
                  metrics: {
                    totalEvents: {
                      value: Number(element.metricValues[0]["value"]),
                      percent: 0,
                    },
                    uniqueEvents: {
                      value: Number(element.metricValues[1]["value"]),
                      percent: 0,
                    },
                  },
                });
              }
            }
            console.log("eventArray : ", this.eventsArray);
            const batchEventsArray = [];
            for (let index = 0; index < this.eventsArray.length; index++) {
              const [eventType, id, pageId, language] =
                this.eventsArray[index].dimensions.split("_");

              if (eventType === "multi-call-to-action") {
                //Clean events name for display
                const page = this.listOfPages.find(
                  (page) => page.key === `${language}_${pageId}`
                );
                if (page === undefined)
                  this.eventsArray[
                    index
                  ].label = `Multi call to action - Unknown page`;
                else
                  this.eventsArray[
                    index
                  ].label = `Multi call to action ${id} - ${page.value}`;
                batchEventsArray.push(this.eventsArray[index]);
                //Calcul purcentage
                this.eventsArray[index].metrics.totalEvents.percent =
                  (this.eventsArray[index].metrics.totalEvents.value /
                    totalEventsCallToAction[
                      `${eventType}_${language}_${pageId}`
                    ].totalEvents) *
                  100;
                this.eventsArray[index].metrics.uniqueEvents.percent =
                  (this.eventsArray[index].metrics.uniqueEvents.value /
                    totalEventsCallToAction[
                      `${eventType}_${language}_${pageId}`
                    ].uniqueEvent) *
                  100;
              } else if (eventType === "call-to-action") {
                //Clean events name for display
                const page = this.listOfPages.find(
                  (page) => page.key === `${language}_${pageId}`
                );
                if (page === undefined)
                  this.eventsArray[
                    index
                  ].label = `Call to action - Unknown page`;
                else
                  this.eventsArray[
                    index
                  ].label = `Call to action - ${page.value}`;
                batchEventsArray.push(this.eventsArray[index]);
                //Calcul purcentage
                this.eventsArray[index].metrics.totalEvents.percent =
                  (this.eventsArray[index].metrics.totalEvents.value /
                    totalEventsCallToAction["call-to-action"].totalEvents) *
                  100;
                this.eventsArray[index].metrics.uniqueEvents.percent =
                  (this.eventsArray[index].metrics.uniqueEvents.value /
                    parseInt(this.UniqueUsers, 10)) *
                  100;
              }
            }
            this.eventsArray = batchEventsArray;
            this.ClickTotalEvents =
              totalEventsCallToAction["call-to-action"].totalEvents.toString();
            this.ClickUniqueEvents =
              totalEventsCallToAction["call-to-action"].uniqueEvent.toString();
          } else {
            console.error("No data in response 12");
            this.ClickUniqueEvents = "0";
            this.ClickTotalEvents = "0";
            this.eventsArray = [];
            this.displayedEventsArray = [];
            this.callToActions = {
              ctaList: [],
              type: null,
              pageId: "",
              label: "",
              color: "",
              language: "",
              metrics: {
                totalEvents: {
                  value: 0,
                  percent: 0,
                },
                uniqueEvents: {
                  value: 0,
                  percent: 0,
                },
              },
              rate: {
                value: 0,
              },
            };
          }
          if (
            valuesGoogleApi[13] != undefined &&
            this.testResponseAPI(valuesGoogleApi[13])
          ) {
            for (let i = 0; i < valuesGoogleApi[13]["rows"].length; i++) {
              if (i === valuesGoogleApi[13]["rows"].length - 1) {
                this.DailyEventData = this.SimplifyReturnGoogleAPI(
                  JSON.parse(JSON.stringify(valuesGoogleApi[13]))
                );
                this.CumulatedEventData = this.GenerateCumulatedData(
                  JSON.parse(JSON.stringify(this.DailyEventData))
                );
                this.dailyDataEventChart = this.transformForChart(
                  JSON.parse(JSON.stringify(this.DailyEventData))
                );
                this.cumulatedEventDataChart = this.transformForChart(
                  JSON.parse(JSON.stringify(this.CumulatedEventData))
                );
              }
            }
          } else {
            console.error("No data in response 13");
            this.dailyDataEventChart["labels"].length = 0;
            this.dailyDataEventChart["values"].length = 0;
            this.cumulatedEventDataChart["labels"].length = 0;
            this.cumulatedEventDataChart["values"].length = 0;
          }
          if (
            valuesGoogleApi[14] &&
            this.testResponseAPI(valuesGoogleApi[14])
          ) {
            var data = this.SimplifyReturnGoogleAPI(
              JSON.parse(JSON.stringify(valuesGoogleApi[14]))
            );
            for (let k = 0; k < data["rows"].length; k++) {
              listOfSourceMedium.listOfSourceMedium_GA.push(
                data["rows"][k]["dimensions"]
                  .replace(/\s/g, "")
                  .replace(/\//g, "")
              );
            }
          } else {
            console.error("No data in response 14");
            listOfSourceMedium.listOfSourceMedium_GA.length = 0;
          }
        } else {
          console.error("No data for getData()");
        }

        if (valuesElefightApi) {
          var listOfSourceMediumScanned = [];
          let listOfDimensions = [];

          valuesElefightApi.forEach((SITE) => {
            this.AnswerTotal = (
              parseInt(this.AnswerTotal, 10) +
              parseInt(SITE["ANSWERS_COUNT"], 10)
            ).toString();
            this.ScansNumber = String(
              parseInt(this.ScansNumber) +
                (SITE.TOTAL_VISIT ? SITE.TOTAL_VISIT : 0)
            );
            this.ConvertionRateLink3 = (
              (parseInt(this.AnswerTotal, 10) /
                parseInt(this.ClickTotalEvents, 10)) *
              100
            )
              .toFixed(2)
              .toString();
            if (
              this.ConvertionRateLink3 === "NaN" ||
              this.ConvertionRateLink3 == undefined
            )
              this.ConvertionRateLink3 = "0";
            this.ConvertionRateLink2 = (
              (parseInt(this.ClickUniqueEvents, 10) /
                parseInt(this.UniqueUsers, 10)) *
              100
            )
              .toFixed(2)
              .toString();
          });
          for (let i = 0; i < valuesElefightApi.length; i++) {
            let scans_current_site = 0;
            if (valuesElefightApi[i]["LIST_OF_SURVEYS"].length > 0)
              this.hasSurveys = true;
            for (let j = 0; j < valuesElefightApi[i]["QRCODES"].length; j++) {
              let current_qr = {
                source: valuesElefightApi[i]["QRCODES"][j]["LOC1"],
                medium: valuesElefightApi[i]["QRCODES"][j]["LOC2"],
                key:
                  valuesElefightApi[i]["QRCODES"][j]["LOC1"]
                    .replace(/\s/g, "")
                    .replace(/\//g, "") +
                  valuesElefightApi[i]["QRCODES"][j]["LOC2"]
                    .replace(/\s/g, "")
                    .replace(/\//g, ""),
              };
              listOfSourceMedium.listOfSourceMedium_QRCODES.push(current_qr);
              listOfDimensions.push({
                dimensions: current_qr.source + " / " + current_qr.medium,
              });

              //If analytics data array in qr code -> add qr code scans to total scans
              if (
                valuesElefightApi[i]["QRCODES"][j].hasOwnProperty("ANALYTICS")
              ) {
                let scans_current_qr = parseInt(
                  valuesElefightApi[i]["QRCODES"][j]["ANALYTICS"].length
                );

                if (scans_current_qr == 0) {
                  this.listOfSourceMediumNotScanned.push(current_qr);
                } else {
                  //Qr has scans
                  listOfSourceMediumScanned.push(current_qr);

                  scans_current_site = scans_current_site + scans_current_qr;

                  //Get all timestamp of all cookies in a single array
                  this.DailyScanData = this.DailyScanData.concat(
                    JSON.parse(
                      JSON.stringify(
                        valuesElefightApi[i]["QRCODES"][j]["ANALYTICS"]
                      )
                    )
                  );
                  //add scans to top10Array to blend GA data with elefight data
                  let loc1 = valuesElefightApi[i]["QRCODES"][j]["LOC1"].trim();
                  let loc2 = valuesElefightApi[i]["QRCODES"][j]["LOC2"].trim();
                  let qrCodeName = loc1 + " / " + loc2;
                  let qrCodeInArrayIndex = this.top10array.findIndex(
                    (item) => item.dimension === qrCodeName
                  );
                  let qrCodeInArray =
                    qrCodeInArrayIndex !== -1
                      ? this.top10array[qrCodeInArrayIndex]
                      : null;

                  if (qrCodeInArray != null) {
                    qrCodeInArray.metrics.scansTotal.value += scans_current_qr;
                    qrCodeInArray.metrics.scansTotal.percent =
                      (qrCodeInArray.metrics.scansTotal.value /
                        (parseInt(this.ScansNumber) +
                          qrCodeInArray.metrics.scansTotal.value)) *
                      100;
                  } else {
                    this.top10array.push({
                      dimension: qrCodeName,
                      metrics: {
                        scans: {
                          value: 0,
                          percent: 0,
                        },
                        uniqueUsers: {
                          value: 0,
                          percent: 0,
                        },
                        bounceRate: {
                          value: 0,
                          percent: 0,
                        },
                        averageSpentTime: {
                          value: "00:00:00",
                          percent: 0,
                        },
                        newUsers: {
                          value: 0,
                          percent: 0,
                        },
                        returningUsers: {
                          value: 0,
                          percent: 0,
                        },
                        scansTotal: {
                          value: scans_current_qr,
                          percent: 0,
                        },
                      },
                    });
                  }
                }
              } else {
                //If no ANALYTICS array the qrcode is not scanned yet
                this.listOfSourceMediumNotScanned.push(current_qr);
              }
            }

            //Add qrcode location source & medium in filter arrays
            this.locations1Links = LocationsDependency.locationLinks(
              listOfDimensions,
              LocationsDependency.mediumReducer,
              "REPORTING"
            );
            this.sourceArray = this.locations1Links.get(this.SELECT_VALUE);
            if (isNgOnInit)
              this.selectedLocation1 = [...this.sourceArray, this.SELECT_VALUE];
            this.locations2Links = LocationsDependency.locationLinks(
              listOfDimensions,
              LocationsDependency.sourceReducer,
              "REPORTING"
            );
            this.mediumArray = this.locations2Links.get(this.SELECT_VALUE);
            if (isNgOnInit)
              this.selectedLocation2 = [...this.mediumArray, this.SELECT_VALUE];

            let indexInSiteArray = this.sitesArray.findIndex(
              (site) => site.dimensions === valuesElefightApi[i]["SITE"]
            );
            if (indexInSiteArray !== -1) {
              this.sitesArray[indexInSiteArray].scans = scans_current_site;
            }
          }

          // calcul new cookies refusal rate round to 2 decimals (since first Elefight scan date) = (total Elefight scan - cookies accepted) / total Elefight scan * 100
          if (
            this.DailyScanData &&
            Object.keys(this.DailyScanData).length > 0
          ) {
            this.DailyScanData = this.SimplifyReturnDataScan(
              this.DailyScanData
            );

            let firstScanLabel = null;
            let gaMetricCount = 0;

            // Get first Elefight scan date
            for (let i = 0; i < this.DailyScanData["rows"].length; i++) {
              const row = this.DailyScanData["rows"][i];
              if (row.metrics > 0) {
                firstScanLabel = row.dimensions;
                break;
              }
            }

            if (this.DailyData && this.DailyData?.rows.length > 0) {
              // Count number of cookie accepted since first Elefight scan date
              let startCounting = false;
              for (let i = 0; i < this.DailyData.rows.length; i++) {
                const dailyData = this.DailyData.rows[i];
                if (startCounting) {
                  gaMetricCount += dailyData.metrics;
                } else if (dailyData.dimensions === firstScanLabel) {
                  startCounting = true;
                  gaMetricCount += dailyData.metrics;
                }
              }
            }

            // Calculate cookies refusal since this first Elefight scan date
            if (
              this.ScansNumber !== "0" &&
              parseInt(this.ScansNumber) >= parseInt(this.CookiesAcceptedNumber)
            ) {
              this.CookiesRefusedRate = String(
                Math.round(
                  ((parseInt(this.ScansNumber) -
                    parseInt(this.CookiesAcceptedNumber)) /
                    parseInt(this.ScansNumber)) *
                    100 +
                    Number.EPSILON
                )
              );
              this.CookiesAcceptedRate = String(
                Math.round(
                  (1 - parseFloat(this.CookiesRefusedRate) / 100) * 100 +
                    Number.EPSILON
                )
              );
            } else {
              this.CookiesRefusedRate = "0";
              this.CookiesAcceptedRate = "0";
            }

            this.dailyDataScanChart = this.transformForChart(
              this.DailyScanData
            );
            this.CumulatedScanData = this.GenerateCumulatedData(
              this.DailyScanData
            );
            this.cumulatedDataScanChart = this.transformForChart(
              this.CumulatedScanData
            );
          }

          this.SelectedQRCodes = listOfSourceMediumScanned.length.toString();
          this.TotalSelectedQRCodes =
            listOfSourceMedium["listOfSourceMedium_QRCODES"].length.toString();

          //On aligne le contenu de selectedLocation1 et selectedLocation2 avec le contenu de sourceArray et mediumArray
          this.selectedLocation1 = [
            ...this.selectedLocation1.filter(
              (location1) => this.sourceArray.indexOf(location1) > -1
            ),
            this.SELECT_VALUE,
          ];
          this.selectedLocation2 = [
            ...this.selectedLocation2.filter(
              (location2) => this.mediumArray.indexOf(location2) > -1
            ),
            this.SELECT_VALUE,
          ];
        } else {
          console.error("No data in response elefight api");
          this.SelectedQRCodes = "0";
          this.AnswerTotal = "0";
          this.ConvertionRateLink3 = "0";
          this.dailyDataScanChart["labels"].length = 0;
          this.dailyDataScanChart["values"].length = 0;
          this.cumulatedDataScanChart["labels"].length = 0;
          this.cumulatedDataScanChart["values"].length = 0;
          listOfSourceMedium.listOfSourceMedium_QRCODES.length = 0;
          this.listOfSourceMediumNotScanned.length = 0;
        }
        this.DATA_IS_LOADED = true;
        this.DATA_IS_LOADING = false;
        this.lastRefresh = new Date();
        this.changeDetection.detectChanges();
        this.setDailyChart([], "scan", "bar");
        this.setDailyChart([], "cookie", "bar");
        this.onChangePagesMultiCallToActionsEvents(
          `${this.SITES_METADATA[0]["LANGUAGES"][0]}_home`
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /** Function to reset all figures in the dashboard */
  resetAllFigures() {
    this.SelectedQRCodes = "0";
    this.TotalSelectedQRCodes = "0";
    this.CookiesAcceptedNumber = "0";
    this.TotalCookiesAccepted = "0";
    this.CookiesRefusedRate = "0";
    this.CookiesAcceptedRate = "0";
    this.ScansNumber = "0";
    this.UniqueUsers = "0";
    this.TotalUniqueUsers = "0";
    this.BounceRate = "0";
    this.AverageSpentTime = "0";
    this.RetentionRateLink1 = "0";
    this.StayingUsers = "0";
    this.StayingScans = "0";
    this.ConvertionRateLink2 = "0";
    this.ClickUniqueEvents = "0";
    this.ClickTotalEvents = "0";
    this.ConvertionRateLink3 = "0";
    this.AnswerTotal = "0";
    this.message_error.length = 0;
    this.listOfSourceMediumNotScanned.length = 0;
    this.dimensionFilterExpressions.length = 0;
    this.bodyFilterClauses = {
      startDate: 0,
      endDate: 0,
      sites: [],
      location1: [],
      location2: [],
      beginWith: "",
      surveys: [],
    };
    this.hasSurveys = false;
    this.DailyScanData = [];
    this.CumulatedScanData = [];
    this.dailyDataScanChart = { labels: [], values: [] };
    this.cumulatedDataScanChart = { labels: [], values: [] };
    // this.selectedLocation1 = [];
    // this.selectedLocation2 = [];
    this.top10array = [];
    this.eventsArray = [];
    this.displayedEventsArray = [];
  }

  /** Function to simplify the result of Google Analytics API */
  SimplifyReturnGoogleAPI(data: any): any {
    var newData = {};
    if (data["maximums"])
      newData["maximums"] = Number(
        data["maximums"][0]["metricValues"][0]
          ? data["maximums"][0]["metricValues"][0]["value"]
          : 0
      );
    if (data["minimums"])
      newData["minimums"] = Number(
        data["minimums"][0]["metricValues"][0]
          ? data["minimums"][0]["metricValues"][0]["value"]
          : 0
      );
    if (data["rowCount"]) newData["rowCount"] = Number(data["rowCount"]);
    if (data["totals"])
      newData["totals"] = Number(
        data["totals"][0]["metricValues"][0]
          ? data["totals"][0]["metricValues"][0]["value"]
          : 0
      );
    if (data["rows"] && data["rows"].length > 0) {
      newData["rows"] = [];
      for (let i = 0; i < data["rows"].length; i++) {
        if (
          data["rows"][i]["dimensionValues"][0]["value"] ===
          "colombiaperoucovid"
        )
          data["rows"][i]["dimensionValues"][0]["value"] =
            "ELEFIGHT_COVID_COPAC";
        if (
          data["rows"][i]["dimensionValues"][0]["value"].toLowerCase() ===
          "waterford"
        )
          data["rows"][i]["dimensionValues"][0]["value"] =
            "ELEFIGHT_COVID_WATERFORD";
        newData["rows"].push({
          dimensions: data["rows"][i]["dimensionValues"][0]
            ? data["rows"][i]["dimensionValues"][0]["value"]
            : "",
          metrics: Number(
            data["rows"][i]["metricValues"][0]
              ? data["rows"][i]["metricValues"][0]["value"]
              : 0
          ),
        });
      }
    }
    return newData;
  }

  timestampToDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  }

  completeDataWithZeros(data, startTimestamp, endTimestamp) {
    const result = [];
    const sortedData = data
      .slice()
      .sort((a, b) => a.dimensions.localeCompare(b.dimensions));
    let currentDate = new Date(startTimestamp);
    let index = 0;

    while (currentDate <= new Date(endTimestamp)) {
      const currentDateStr = `${currentDate.getFullYear()}${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDate.getDate().toString().padStart(2, "0")}`;

      if (
        sortedData[index] &&
        sortedData[index].dimensions === currentDateStr
      ) {
        result.push(sortedData[index]);
        index++;
      } else {
        result.push({ dimensions: currentDateStr, metrics: 0 });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  }

  SimplifyReturnDataScan(data: any): any {
    var newData = {};
    const counts = {};

    if (data.length > 0) {
      newData["minimums"] = Number();
      newData["maximums"] = Number();
      newData["rowCount"] = Number(data.length);
      newData["rows"] = [];

      for (let i = 0; i < data.length; i++) {
        var actual_timestamp = data[i];
        var date = this.timestampToDate(Number(actual_timestamp));

        if (counts[String(date)]) {
          counts[String(date)]++;
        } else {
          counts[String(date)] = 1;
        }
      }

      for (const [date, count] of Object.entries(counts)) {
        newData["rows"].push({ dimensions: date, metrics: count });
      }

      newData["rows"] = this.completeDataWithZeros(
        newData["rows"],
        this.bodyFilterClauses.startDate,
        this.bodyFilterClauses.endDate
      );
    }
    return newData;
  }

  /** Function to simplify the result of Google Analytics API */
  SimplifyReturnGoogleAPI_TOP(data: any): any {
    var newData = {};
    if (data["maximums"])
      newData["maximums"] = data["maximums"][0]["metricValues"];
    if (data["minimums"])
      newData["minimums"] = data["minimums"][0]["metricValues"];
    if (data["totals"]) newData["totals"] = data["totals"][0]["metricValues"];
    if (data["rowCount"]) newData["rowCount"] = Number(data["rowCount"]);
    if (data["rows"]) {
      newData["rows"] = [];
      for (let i = 0; i < data["rows"].length; i++) {
        var dimension = data["rows"][i]["dimensionValues"][0]["value"];
        var metrics = {
          scans: {
            value: Number(data["rows"][i]["metricValues"][0]["value"]),
            percent:
              (Number(data["rows"][i]["metricValues"][0]["value"]) /
                newData["totals"][0]) *
              100,
          },
          uniqueUsers: {
            value: Number(data["rows"][i]["metricValues"][1]["value"]),
            percent:
              (Number(data["rows"][i]["metricValues"][1]["value"]) /
                newData["totals"][1]) *
              100,
          },
          bounceRate: {
            value: Number(
              parseFloat(
                data["rows"][i]["metricValues"][2]["value"].replace(/,/g, ".")
              ).toFixed(2)
            ),
            percent:
              (Number(data["rows"][i]["metricValues"][2]["value"]) /
                newData["totals"][2]) *
              100,
          },
          averageSpentTime: {
            value: this.secondsToHms(
              Number(data["rows"][i]["metricValues"][3]["value"])
            ),
            percent:
              (Number(data["rows"][i]["metricValues"][3]["value"]) /
                newData["totals"][3]) *
              100,
          },
          newUsers: {
            value: Number(data["rows"][i]["metricValues"][4]["value"]),
            percent:
              (Number(data["rows"][i]["metricValues"][4]["value"]) /
                newData["totals"][4]) *
              100,
          },
          returningUsers: {
            value:
              Number(data["rows"][i]["metricValues"][1]["value"]) -
              Number(data["rows"][i]["metricValues"][4]["value"]),
            percent:
              ((Number(data["rows"][i]["metricValues"][1]["value"]) -
                Number(data["rows"][i]["metricValues"][4]["value"])) /
                newData["maximums"][0]) *
              100,
          },
          scansTotal: {
            value: 0,
            percent: 0,
          },
        };
        newData["rows"].push({
          dimension: dimension,
          metrics: metrics,
        });
      }
    }
    return newData;
  }

  /** Recreate cumulated data for get sessions per day */
  GenerateCumulatedData(data: any) {
    let newData = JSON.parse(JSON.stringify(data));
    if (data && data["rows"] && data["rows"].length > 0) {
      for (let i = 1; i < data["rows"].length; i++) {
        newData["rows"][i]["metrics"] =
          newData["rows"][i]["metrics"] + newData["rows"][i - 1]["metrics"];
      }
    }
    return newData;
  }

  /** Transform receive data for chart (labels / values) */
  transformForChart(data: any) {
    var dataChart = {
      labels: [],
      values: [],
    };
    if (Object.keys(data).length !== 0) {
      for (let i = 0; i < data["rows"].length; i++) {
        dataChart["values"].push(data["rows"][i]["metrics"]);
        var date = new Date(
          data["rows"][i]["dimensions"].substring(0, 4),
          data["rows"][i]["dimensions"].substring(4, 6) - 1,
          data["rows"][i]["dimensions"].substring(6, 8)
        );
        var stringDate = date.toLocaleDateString("en-EN", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        dataChart["labels"].push(stringDate);
      }
    }

    return dataChart;
  }

  changeDailyChartScanData(event: any) {
    var dataset = [];
    if (this.scansPerDayCheckedDailyChart === true) {
      dataset.push({
        data: this.dailyDataScanChart["values"],
        backgroundColor: COLORS_["color-primary-dark"],
        borderColor: COLORS_["color-primary-dark"],
        label: "Number of visits / day",
      });
    }
    if (this.cumulScansPeriodCheckedDailyChart === true) {
      dataset.push({
        data: this.cumulatedDataScanChart["values"],
        borderColor: COLORS_["color-primary-dark"],
        label: "Cumulated number of scans over the period",
        type: "line",
      });
    }
    if (this.CookAcceptPerDayCheckedDailyChart === true) {
      dataset.push({
        data: this.dailyDataCookieChart["values"],
        backgroundColor: "#8D96D7",
        borderColor: "#8D96D7",
        label: "Number of visits with cookies accepted / day",
      });
    }
    if (this.cumulCookAcceptPeriodCheckedDailyChart === true) {
      dataset.push({
        data: this.cumulatedDataCookieChart["values"],
        borderColor: "#8D96D7",
        label:
          "Cumulated number of Visits with cookies accepted over the period",
        type: "line",
      });
    }

    this.setDailyChart(dataset, "scan", "bar");
  }

  /** Set charts in the template */
  setDailyChart(dataset: any, type: string, chartType: string) {
    //get sorted merged labels
    const mergedLabels: string[] = Array.from(
      new Set([
        ...this.dailyDataScanChart.labels,
        ...this.dailyDataCookieChart.labels,
      ])
    ).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);

      return dateA.getTime() - dateB.getTime();
    });

    //For each label if present in this.dailyDataScanChart.labels return associated value or return 0
    const mergedValuesScanChart: number[] = mergedLabels.map((label) => {
      const currentScanIndex = this.dailyDataScanChart.labels.indexOf(label);
      return currentScanIndex !== -1
        ? this.dailyDataScanChart.values[currentScanIndex]
        : 0;
    });

    //For each label if present in this.dailyDataCookieChart.labels return associated value or return 0
    const mergedValuesCookieChart: number[] = mergedLabels.map((label) => {
      const currentCookieIndex =
        this.dailyDataCookieChart.labels.indexOf(label);
      return currentCookieIndex !== -1
        ? this.dailyDataCookieChart.values[currentCookieIndex]
        : 0;
    });

    //Affect new labels and values to chart data arrays
    this.dailyDataScanChart.labels = mergedLabels;
    this.dailyDataScanChart.values = mergedValuesScanChart;

    this.dailyDataCookieChart.labels = mergedLabels;
    this.dailyDataCookieChart.values = mergedValuesCookieChart;

    //destroy chart if exist to avoid error when recreate other ones
    if (type === "scan") {
      this.myDailyScanChart =
        this.myDailyScanChart === undefined
          ? this.myDailyScanChart
          : this.myDailyScanChart.destroy();
    }

    //If chart is init to all datasets
    if (dataset.length == 0 && type !== "scan") {
      dataset.push(
        {
          data: this.dailyDataEventChart["values"],
          backgroundColor: COLORS_["color-primary-dark"],
          borderColor: COLORS_["color-primary-dark"],
          label: "Number of clicks / day",
        },
        {
          data: this.cumulatedEventDataChart["values"],
          borderColor: COLORS_["color-primary-dark"],
          label: "Cumulated number of clicks over the period",
          type: "line",
        }
      );
    } else if (dataset.length == 0 && type === "scan") {
      dataset.push(
        {
          data: this.dailyDataScanChart["values"],
          backgroundColor: COLORS_["color-primary-dark"],
          borderColor: COLORS_["color-primary-dark"],
          label: "Number of scans / day",
        },
        {
          data: this.cumulatedDataScanChart["values"],
          borderColor: COLORS_["color-primary-dark"],
          label: "Cumulated number of scans over the period",
          type: "line",
        },
        {
          data: this.dailyDataCookieChart["values"],
          backgroundColor: "#8D96D7",
          borderColor: "#8D96D7",
          label: "Number of visits with cookies accepted / day",
        },
        {
          data: this.cumulatedDataCookieChart["values"],
          borderColor: "#8D96D7",
          label:
            "Cumulated number of Visits with cookies accepted over the period",
          type: "line",
        }
      );
    }

    var DailyCanvas: any = document.getElementById("myDailyScanChart");
    var ctxDailyChart: any = DailyCanvas.getContext("2d");

    var chartObject = {
      type: chartType,
      data: {
        datasets: [...dataset],
        labels:
          type === "scan" ? mergedLabels : this.dailyDataEventChart["labels"],
      },
      options: {
        legend: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: false,
              },
              gridLines: {
                color: COLORS_["color-opacity-black"],
                drawBorder: false,
                zeroLineColor: COLORS_["color-negative"],
              },
              ticks: {
                fontColor: COLORS_["color-negative"],
              },
              scaleLineColor: "transparent",
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
              },
              gridLines: {
                zeroLineColor: COLORS_["color-negative"],
              },
              ticks: {
                min: 0,
                fontColor: COLORS_["color-negative"],
              },
            },
          ],
        },
      },
    };

    if (type === "scan") {
      this.myDailyScanChart = new Chart(ctxDailyChart, chartObject);
    }
  }

  // Receive user input and send to searchCampaign method**
  onKeyCampaignInput(value) {
    this.tempSelectedCampaigns = [...this.selectedCampaigns];
    let SITES_temp = [...this.SITES];
    this.filteredCampaigns = SITES_temp.filter((site) =>
      site.toLowerCase().includes(value.toLowerCase())
    );
  }

  // Filter the SITES list and send back to populate the selectedSites
  changeCampaignSelection(event) {
    if (event.isUserInput) {
      if (
        event.source.selected &&
        this.selectedCampaigns.indexOf(event.source.value) === -1
      ) {
        this.selectedCampaigns.push(event.source.value);
      } else if (!event.source.selected) {
        this.selectedCampaigns.splice(
          this.selectedCampaigns.indexOf(event.source.value),
          1
        );
      }
    }

    //If selected campaign length =1, display campaign information
    if (
      this.selectedCampaigns.length == 1 &&
      !this.selectedCampaigns.includes(this.SELECT_VALUE)
    ) {
      let site: any = this.SITES_METADATA.find(
        (site) => site["SITE"] == this.selectedCampaigns[0].toUpperCase()
      );
      this.campaignDateAnalytics = site.MISC.CAMPAIGN_DATE_ANALYTICS;
    }
  }

  @ViewChild("allCampaignSelected") private allCampaignSelected: MatOption;
  @ViewChild("allMediumSelected") private allMediumSelected: MatOption;
  @ViewChild("allSourceSelected") private allSourceSelected: MatOption;

  toggleCampaignPerOne() {
    if (this.allCampaignSelected.selected) {
      this.selectedCampaigns.splice(
        this.selectedCampaigns.indexOf(this.SELECT_VALUE),
        1
      );
      this.allCampaignSelected.deselect();
    }

    if (this.SITES.length == this.selectedCampaigns.length) {
      this.selectedCampaigns = [...this.SITES, this.SELECT_VALUE];
      this.allCampaignSelected.select();
    }
  }

  toggleAllCampaignSelection() {
    if (this.allCampaignSelected.selected) {
      this.selectedCampaigns = [...this.SITES, this.SELECT_VALUE];
    } else {
      this.selectedCampaigns = [];
    }
    this.tempSelectedCampaigns = [...this.selectedCampaigns];
  }

  toggleSourcePerOne(all) {
    if (this.allSourceSelected.selected) {
      this.selectedLocation1.splice(
        this.selectedLocation1.indexOf(this.SELECT_VALUE),
        1
      );
      this.allSourceSelected.deselect();
    }
    if (this.sourceArray.length == this.selectedLocation1.length) {
      this.selectedLocation1 = [...this.sourceArray, this.SELECT_VALUE];
      this.allSourceSelected.select();
    }

    let tabLocs2 = [];
    this.selectedLocation1.forEach((selectL1) => {
      tabLocs2 = this.arrayUnique(
        tabLocs2.concat(this.locations2Links.get(selectL1))
      );
    });
    this.mediumArray = tabLocs2;
    this.selectedLocation2 = tabLocs2;

    if (this.mediumArray.length == this.selectedLocation2.length) {
      this.selectedLocation2 = [...tabLocs2, this.SELECT_VALUE];
    }
    if (this.mediumArray.length === 0) {
      this.mediumArray = this.locations2Links.get(this.SELECT_VALUE);
    }
    console.info("selectedLocation1: ", this.selectedLocation1);
  }

  toggleAllSourceSelection() {
    if (this.allSourceSelected.selected) {
      let tabLocs2 = [];
      this.selectedLocation1.forEach((selectL1) => {
        tabLocs2 = this.arrayUnique(
          tabLocs2.concat(this.locations2Links.get(selectL1))
        );
      });
      this.mediumArray = tabLocs2;
      this.selectedLocation2 = tabLocs2;
      this.selectedLocation1 = [...this.sourceArray, this.SELECT_VALUE];
      this.selectedLocation2 = [...this.mediumArray, this.SELECT_VALUE];
    } else {
      this.selectedLocation1 = [];
      this.selectedLocation2 = [];
    }
    console.info("selectedLocation1: ", this.selectedLocation1);
  }

  toggleMediumPerOne(all) {
    if (this.allMediumSelected.selected) {
      this.selectedLocation2.splice(
        this.selectedLocation2.indexOf(this.SELECT_VALUE),
        1
      );
      this.allMediumSelected.deselect();
    }
    if (this.mediumArray.length == this.selectedLocation2.length) {
      this.selectedLocation2 = [...this.mediumArray, this.SELECT_VALUE];
      this.allMediumSelected.select();
    }

    let tabLocs1 = [];
    this.selectedLocation2.forEach((selectL2) => {
      tabLocs1 = this.arrayUnique(
        tabLocs1.concat(this.locations1Links.get(selectL2))
      );
    });
    this.selectedLocation1 = tabLocs1;

    if (this.sourceArray.length == this.selectedLocation1.length) {
      this.selectedLocation1 = [...tabLocs1, this.SELECT_VALUE];
    }
    if (this.sourceArray.length === 0) {
      this.sourceArray = this.locations1Links.get(this.SELECT_VALUE);
    }
    console.info("selectedLocation2: ", this.selectedLocation2);
  }
  toggleAllMediumSelection() {
    if (this.allMediumSelected.selected) {
      let tabLocs1 = [];
      this.selectedLocation2.forEach((selectL2) => {
        tabLocs1 = this.arrayUnique(
          tabLocs1.concat(this.locations1Links.get(selectL2))
        );
      });
      this.selectedLocation1 = tabLocs1;
      this.selectedLocation2 = [...this.mediumArray, this.SELECT_VALUE];
      this.selectedLocation1 = [...this.sourceArray, this.SELECT_VALUE];
    } else {
      this.selectedLocation2 = [];
      this.selectedLocation1 = [];
    }
    console.info("selectedLocation2: ", this.selectedLocation2);
  }

  /** onSearch: get dropdown and set filters on analytics */
  onSearch(isNgOnInit: boolean) {
    this.DATA_IS_LOADED = false;
    console.info("bsRangeValue: ", this.bsRangeValue);
    console.info("searchInput: ", this.searchInput);
    console.info("selectedLocation1: ", this.selectedLocation1);
    console.info("selectedLocation2: ", this.selectedLocation2);
    console.info("selectedCampaigns: ", this.selectedCampaigns);

    this.searchDate[0] = this.formatDate(this.bsRangeValue[0]);
    this.searchDate[1] = this.formatDate(this.bsRangeValue[1]);

    this.resetAllFigures();

    //We remove the value SELECT_VALUE from the selectedLocation
    let selectedLocation1 = this.selectedLocation1.filter(
      (location1) => location1 != this.SELECT_VALUE
    );
    let selectedLocation2 = this.selectedLocation2.filter(
      (location2) => location2 != this.SELECT_VALUE
    );
    let selectedCampaigns = this.selectedCampaigns.filter(
      (campaign) => campaign != this.SELECT_VALUE
    );

    //If we are in LOCAL site, we add a default filter: campaign
    if (this.TYPE === "LOCAL") {
      selectedCampaigns.push(...this.SITE_NAME);
      this.bodyFilterClauses.sites = [this.SITE_NAME];
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionCampaignName",
          stringFilter: {
            matchType: "EXACT",
            value: this.SITE_NAME,
          },
        },
      });
    }

    //If we are in GLOBAL site
    if (this.TYPE === "GLOBAL" || this.TYPE === "ADMIN") {
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionCampaignName",
          inListFilter: {
            values: selectedCampaigns,
          },
        },
      });
      this.bodyFilterClauses.sites = selectedCampaigns;
    }

    //If a source (selectedLocation1) is selected, we add it to filters
    if (
      this.selectedLocation1.length > 0 &&
      !this.selectedLocation1.includes(this.SELECT_VALUE)
    ) {
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionSource",
          inListFilter: {
            values: selectedLocation1,
          },
        },
      });
      this.bodyFilterClauses.location1 = [...selectedLocation1];
    }

    //If a medium (selectedLocation2) is selected, we add it to filters
    if (
      this.selectedLocation2.length > 0 &&
      !this.selectedLocation2.includes(this.SELECT_VALUE)
    ) {
      this.dimensionFilterExpressions.push({
        filter: {
          fieldName: "sessionMedium",
          inListFilter: {
            values: selectedLocation2,
          },
        },
      });
      this.bodyFilterClauses.location2 = [...selectedLocation2];
    }

    //If an input contains is write (searchInput), we add it to filters
    if (this.searchInput && this.searchInput != "null") {
      this.dimensionFilterExpressions.push({
        orGroup: {
          expressions: [
            {
              filter: {
                fieldName: "sessionSource",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: this.searchInput,
                },
              },
            },
            {
              filter: {
                fieldName: "sessionMedium",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: this.searchInput,
                },
              },
            },
          ],
        },
      });
      this.bodyFilterClauses.beginWith = this.searchInput;
    }

    this.bodyFilterClauses.surveys = this.arrayUnique(this.listOfSurveys);

    //We push selected date into bodyFilterClauses array substract 12hours offset timezone
    this.bodyFilterClauses.startDate =
      new Date(this.bsRangeValue[0]).getTime() + 12 * 3600 * 1000;
    this.bodyFilterClauses.endDate =
      new Date(this.bsRangeValue[1]).getTime() + 12 * 3600 * 1000;

    console.info("dimensionFilterClauses :", this.dimensionFilterExpressions);
    console.info("bodyFilterClauses :", this.bodyFilterClauses);

    this.myDailyScanChart =
      this.myDailyScanChart === undefined
        ? this.myDailyScanChart
        : this.myDailyScanChart.destroy();

    if (isNgOnInit) this.SHOW_FILTERS_MODAL = false;
    this.getData(isNgOnInit);
  }

  onReset() {
    this.ngOnInit();
  }

  /**
   * Transform API response into array
   * @param data input data
   * @returns return dropdown array
   */
  private getDropdownArray(data: any) {
    var array = [];
    for (let i = 0; i < data.length; i++) {
      array.push(data[i]["dimensions"]);
    }
    array = [...new Set(array)];
    return array;
  }

  /**
   * Convert number to time hh:mm:ss
   * @param d input number
   * @returns return string hh:mm:ss
   */
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    if (h < 10) var hDisplay = h > 0 ? (h < 10 ? `0${h}:` : `${h}:`) : `00:`;
    var mDisplay = m > 0 ? (m < 10 ? `0${m}:` : `${m}:`) : `00:`;
    var sDisplay = s > 0 ? (s < 10 ? `0${s}` : `${s}`) : `00`;
    return hDisplay + mDisplay + sDisplay;
  }

  /**
   * Convert date to format yyyy-mm-dd
   * @param date input date
   * @returns return string yyyy-mm-dd
   */
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  /**
   * Navigate though components
   * @param route route to reach
   * @param site name of the site
   */
  navigate(route: string, site?: string) {
    if (site) this.router.navigate([`/admin/${route}/${site}`]);
    else this.router.navigate([`/admin/${route}`]);
  }

  /**
   * Function to test if there is data in Google API response
   * @param response response API to test
   * @returns return boolean
   */
  private testResponseAPI(response) {
    return response.rowCount ? true : false;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(message: string, id?: number) {
    if (message)
      this.message_error.push(`Erreur with promises[${id}] : ${message}.`);

    this.DATA_IS_LOADED = false;
    this.DATA_IS_LOADING = false;
  }

  arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
    return a;
  }

  modalDownloadData() {
    console.log("Download Data");
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "downloadData";
    this.ALERT_MESSAGE.MESSAGE =
      "Are you sure you want to download reporting data ?";
  }

  callbackActionModal_downloadData() {
    console.log("GENERATE EXCEL REPORT !");
    let siteName =
      this.TYPE === "GLOBAL" ? "SITE-BASE-GENERAL" : `${this.SITE_NAME}`;

    this._GoogleAPIService
      .generateExcelFile(this.clientUUID, siteName)
      .then((response) => {
        console.log("Response: ", response);

        var currentdate = new Date();
        let archiveName =
          "ELEFIGHT_Data_Extraction_" +
          currentdate.getDate() +
          "-" +
          (currentdate.getMonth() + 1) +
          "-" +
          currentdate.getFullYear() +
          " @ " +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes();

        this._fileManagementService.downloadFile(
          response["URL"],
          `${archiveName}.xlsx`
        );

        this.ALERT_MESSAGE.IS_DISPLAYED = false;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
        this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
        this.ALERT_MESSAGE.MESSAGE = null;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangePagesMultiCallToActionsEvents(event) {
    this.callToActions = {
      ctaList: [],
      type: null,
      pageId: "",
      label: "",
      color: "",
      language: "",
      metrics: {
        totalEvents: {
          value: 0,
          percent: 0,
        },
        uniqueEvents: {
          value: 0,
          percent: 0,
        },
      },
      rate: {
        value: 0,
      },
    };

    const [language, pageId] = event.split("_");
    const translation = this.SITES_TRANSLATIONS.find(
      (translation) => translation.language === language
    );
    const pageTranslation = translation.pages.find(
      (page) => page.id === pageId
    );
    const callToAction = pageTranslation.callToAction;
    let ctaList = callToAction.callToActionMultiCta?.ctaList || [];

    for (let i = 0; i < ctaList.length; i++) {
      const eventMetrics = this.eventsArray.find(
        (item) =>
          item.dimensions === `multi-call-to-action_${i}_${pageId}_${language}`
      );
      ctaList[i].metrics = {
        totalEvents: {
          value: eventMetrics ? eventMetrics.metrics.totalEvents.value : 0,
          percent: eventMetrics ? eventMetrics.metrics.totalEvents.percent : 0,
        },
        uniqueEvents: {
          value: eventMetrics ? eventMetrics.metrics.uniqueEvents.value : 0,
          percent: eventMetrics ? eventMetrics.metrics.uniqueEvents.percent : 0,
        },
      };
    }
    this.callToActions.type = callToAction.type;
    this.callToActions.ctaList = ctaList;
    this.callToActions.pageId = pageId;
    this.callToActions.label = callToAction.label;
    this.callToActions.color = pageTranslation.color;
    this.callToActions.language = language;

    const eventData = this.eventsArray.find(
      (item) => item.dimensions === `call-to-action_null_${pageId}_${language}`
    );
    if (eventData) this.callToActions.metrics = eventData.metrics;

    this.callToActions.rate.value =
      (this.callToActions.metrics.uniqueEvents.value /
        parseInt(this.UniqueUsers, 10)) *
      100;
    if (
      isNaN(this.callToActions.rate.value) ||
      this.callToActions.rate.value == undefined
    )
      this.callToActions.rate.value = 0;

    this.displayedEventsArray = this.eventsArray.filter((item) =>
      item.dimensions.includes(`_${pageId}_${language}`)
    );
  }
}
