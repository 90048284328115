import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs-compat/Subscription";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { ROUTES } from "src/app/core/classes/Routes";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "aac-multi-pages",
  templateUrl: "./multi-pages.component.html",
  styleUrls: ["./multi-pages.component.scss"],
})
export class MultiPagesComponent implements OnInit, OnDestroy {
  inputPath: string = environment.inputPath;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };

  SITE: any = {};
  SITE_NAME: string = "";
  PAGE_ID: string = null;
  PAGE_CONTENT: any;
  PAGE_VIDEOS: Array<any> = [];

  CTA_LABEL: string = "";
  CTA_TYPE: string = "";
  CTA_LINK: string = "";
  CTA_LIST = [];
  currentLanguage: string = "";
  colorHeader: any;

  isLoaded: boolean = false;
  errorMessage: string = null;

  activeSlideIndex: number = 0;

  private currentRoutesSubscribe: Subscription;
  private currentRoutesSubscribeOnLangChange: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private sanitizer: DomSanitizer
  ) {
    /** Get routes information then get site information*/
    this.currentRoutesSubscribe = this._routesService.currentRoutes.subscribe(
      (routes) => {
        this.ROUTES = routes;
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] != undefined)
            this.SITE_NAME = routes.PARAMS["site"].toUpperCase();
          else this.SITE_NAME = "";
          if (routes.PARAMS["id"] != undefined)
            this.PAGE_ID = routes.PARAMS["id"];
          else this.PAGE_ID = null;
          if (this.PAGE_ID === null)
            this.errorMessage = "You need to set a page ID";
          this.getTranslation();
        }
      }
    );
    this.currentRoutesSubscribeOnLangChange =
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getTranslation();
      });
    this._sitesService.getSite(this.SITE_NAME).then((res) => {
      this.SITE = JSON.parse(JSON.stringify(res));
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    console.info("ngOnDestroy multi-pages is executing...");
    this.currentRoutesSubscribe.unsubscribe();
    this.currentRoutesSubscribeOnLangChange.unsubscribe();
  }

  getTranslation() {
    this.translate
      .get("language")
      .subscribe((val) => (this.currentLanguage = val));
    let promisesTranslate = [];
    promisesTranslate.push(this.translate.get(`pages`).toPromise()); //Get pages array
    promisesTranslate.push(this.translate.get("MENU").toPromise()); //Get menu content

    Promise.all(promisesTranslate).then((values) => {
      this.PAGE_CONTENT = values[0].find((value) => value.id === this.PAGE_ID);
      if (this.PAGE_CONTENT != undefined) {
        this.colorHeader = this.PAGE_CONTENT.color;

        if (this.PAGE_CONTENT.length < 1) this.navigate("home");

        if (this.PAGE_CONTENT.type === "VIDEO") {
          this.PAGE_CONTENT.pageVideoFields.forEach((videoField) => {
            const table = this.cleanArray(videoField.url.split("/"));
            if (videoField.url.includes("vimeo.com")) {
              this.PAGE_VIDEOS.push({
                text: videoField.text,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://player.vimeo.com/video/${table[0]}?h=${
                    table[1] || ""
                  }`
                ),
              });
            } else if (
              videoField.url.includes("youtube.com") ||
              videoField.url.includes("youtu.be")
            ) {
              this.PAGE_VIDEOS.push({
                text: videoField.text,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `https://www.youtube.com/embed/${table[0]}`
                ),
              });
            } else {
              this.PAGE_VIDEOS.push({
                text: videoField.text,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  videoField.url
                ),
              });
            }
          });
        }

        this.CTA_TYPE = this.PAGE_CONTENT["callToAction"]["type"];
        this.CTA_LABEL = this.PAGE_CONTENT["callToAction"]["label"];
        switch (this.CTA_TYPE) {
          case "FILE":
            const fileName =
              this.PAGE_CONTENT["callToAction"]["callToActionDocument"][
                "documentPath"
              ];
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            if (extension === "pdf" || extension === "ics")
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/DATA/${extension}/${fileName}`;
            else if (listOfImagesExtensions.includes(extension))
              this.CTA_LINK = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${fileName}`;
            break;
          case "EXTERNAL":
            this.CTA_LINK =
              this.PAGE_CONTENT["callToAction"]["callToActionExternalLink"][
                "url"
              ];
            break;
          case "SURVEY":
            this.CTA_LINK = `/survey/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionSurvey"]["survey"]}`;
            break;
          case "INTERNAL":
            if (
              this.PAGE_CONTENT["callToAction"]["callToActionPage"]["page"] ===
              "home"
            ) {
              this.CTA_LINK = `/home/${this.SITE_NAME}`;
            } else {
              this.CTA_LINK = `/more-information/${this.SITE_NAME}/${this.PAGE_CONTENT["callToAction"]["callToActionPage"]["page"]}`;
            }
            break;
          case "MULTI_CTA":
            this.CTA_LIST =
              this.PAGE_CONTENT["callToAction"]["callToActionMultiCta"][
                "ctaList"
              ];
            break;
          default:
            console.error(`Error ! Type ${this.CTA_TYPE} is not allowed`);
        }

        this.isLoaded = true;
      }
    });
  }

  navigateSlide(direction: string) {
    if (direction === "next") {
      this.activeSlideIndex =
        this.activeSlideIndex === this.PAGE_VIDEOS.length - 1
          ? 0
          : this.activeSlideIndex + 1;
    } else if (direction === "previous") {
      this.activeSlideIndex =
        this.activeSlideIndex === 0
          ? this.PAGE_VIDEOS.length - 1
          : this.activeSlideIndex - 1;
    } else {
      console.error(`Error ! Direction ${direction} is not allowed`);
    }
  }

  cleanArray(array: any[]) {
    const newArray = [];
    const forbidenElement = [
      "https:",
      "http:",
      "",
      "vimeo.com",
      "youtu.be",
      "youtube.com",
      null,
      undefined,
    ];
    array.forEach((element) => {
      if (!forbidenElement.includes(element)) {
        newArray.push(element);
      }
    });
    return newArray;
  }

  isLightColor(color: string) {
    const hex = color.replace("#", "");
    const rgb = hex.match(/.{1,2}/g)?.map((val) => parseInt(val, 16));
    if (rgb) {
      const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
      return yiq > 214; // valeur de luminance pour déterminer si la couleur est claire
    }
    return false;
  }

  navigate(route: string) {
    this.router.navigate([`/${route}/${this.SITE_NAME.toLowerCase()}`]);
  }

  closeModal() {
    this.callbackResetModal(this.ALERT_MESSAGE);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.resetModal();
  }

  resetModal() {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
    this.errorMessage;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error["message"];
  }
}
