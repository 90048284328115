<mat-dialog-content
  [formGroup]="currentEditedCta"
  class="d-flex flex-lg-row flex-md-column flex-sm-column"
>
  <div class="multi_cta_container">
    <div class="cta_container">
      <div class="cta_list_container">
        <div
          class="cta_item"
          [ngClass]="{
            'small-cta': currentEditedCta.value.size === 'Small',
            'medium-cta': currentEditedCta.value.size === 'Medium',
            'large-cta': currentEditedCta.value.size === 'Large'
          }"
          [ngStyle]="{
            background: currentEditedCta.value.bgColor
              ? currentEditedCta.value.bgColor
              : 'rgb(228 228 228 / 90%)'
          }"
        >
          <div class="cta_logo">
            <img
              *ngIf="
                currentEditedCta.value.logo &&
                !currentEditedCta.value.currentUploadedLogo
              "
              [src]="
                ENVIRONMENT_PATH +
                'SITES/' +
                SITE_NAME +
                '-PREVIEW' +
                '/IMG/' +
                currentEditedCta.value.logo
              "
              width="50px"
              height="50px"
              alt="Logo"
            />
            <img
              *ngIf="currentEditedCta.value.currentUploadedLogo"
              id="outputImage"
              width="50px"
              height="50px"
              alt="Logo"
            />
          </div>

          <div
            *ngIf="currentEditedCta.value.text !== ''"
            class="cta_text_container"
          >
            <p
              class="labelLike"
              [ngStyle]="{
                color: currentEditedCta.value.textColor
                  ? currentEditedCta.value.textColor
                  : '#2b2b38'
              }"
              [innerHTML]="currentEditedCta.value.text"
            ></p>
          </div>

          <div
            class="btn_standalone_link"
            [style.borderColor]="
              currentEditedCta.value.textColor
                ? currentEditedCta.value.textColor
                : '#525CA3'
            "
          >
            <svg
              xmlns=" http://www.w3.org/2000/svg"
              height="1em"
              fill="#525CA3"
              viewBox="0 0 448 512"
              [ngStyle]="{
                fill: currentEditedCta.value.textColor
                  ? currentEditedCta.value.textColor
                  : '#525CA3'
              }"
            >
              <path
                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [formGroup]="currentEditedCta" class="modal_form">
    <!-- Size -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Size</span>
      <select id="ctaSize" class="form-control" formControlName="size">
        <option value="Small">Small</option>
        <option value="Medium">Medium</option>
        <option value="Large">Large</option>
      </select>
    </div>

    <!-- Text -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Text</span>
      <ckeditor
        class="ckEditor mb-1"
        [editor]="Editor"
        [config]="editorConfig"
        id="text_editor"
        formControlName="text"
      ></ckeditor>
    </div>

    <!-- Text Color -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Text Color</span>
      <input
        type="button"
        class="form-control mb-2"
        formControlName="textColor"
        [value]="currentEditedCta.value.textColor"
        (click)="togglePicker('textColorEditorDisplayed')"
        [ngStyle]="{
          'background-color': currentEditedCta.value.textColor,
          color: isLightColor(currentEditedCta.value.textColor)
            ? '#000000'
            : '#ffffff'
        }"
      />
      <span
        *ngIf="toggle.textColorEditorDisplayed"
        [cpToggle]="true"
        [cpDialogDisplay]="'inline'"
        [(colorPicker)]="currentEditedCta.value.textColor"
        (colorPickerChange)="updateCurrentEditedCta()"
      >
      </span>
    </div>

    <!-- Background Color -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Background Color</span>
      <input
        type="button"
        class="form-control mb-2"
        formControlName="bgColor"
        [value]="currentEditedCta.value.bgColor"
        (click)="togglePicker('backgroundColorEditorDisplayed')"
        [ngStyle]="{
          'background-color': currentEditedCta.value.bgColor,
          color: isLightColor(currentEditedCta.value.bgColor)
            ? '#000000'
            : '#fffffff'
        }"
      />
      <span
        *ngIf="toggle.backgroundColorEditorDisplayed"
        [cpToggle]="true"
        [cpDialogDisplay]="'inline'"
        [(colorPicker)]="currentEditedCta.value.bgColor"
        (colorPickerChange)="updateCurrentEditedCta()"
      >
      </span>
    </div>

    <!-- Logo -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Logo</span>
      <div class="row m-0">
        <div class="col-md-6 px-0">
          <input
            [id]="'ctaLogo_' + currentEditedCtaIndex"
            class="form-control custom-file-input"
            type="file"
            accept="image/*, .ics"
            (change)="onChangeFile($event, 'callToActionLogo')"
            formControlName="logo"
          />
          <label
            [for]="'ctaLogo_' + currentEditedCtaIndex"
            class="custom-file-label"
            >Upload</label
          >
          <div
            *ngIf="currentEditedCta.get('logo').invalid"
            class="alert alert-danger"
          >
            <div *ngIf="currentEditedCta.get('logo').errors.invalidFileSize">
              This file is too large. It must be less than 2MB.
            </div>
            <div *ngIf="currentEditedCta.get('logo').errors.extension">
              File format not allowed. It must be an image, PDF or Calendar.
            </div>
            <div
              *ngIf="currentEditedCta.get('logo').errors.unauthorizedFileName"
            >
              File name not allowed. It must not contains any special characters
              other than "-" and "_".
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <p class="labelLike">
            {{ currentEditedCta.value.logo || "No logo" }}
          </p>
        </div>
      </div>
    </div>

    <!-- Action -->
    <div class="form-group">
      <span class="labelSectionPageEdition">Action</span>
      <select
        id="ctaAction"
        class="form-control"
        [formControl]="currentEditedCta.get('action.type')"
      >
        <option value="Internal">Internal Page</option>
        <option value="OpenFile">
          Images (.png or .jpeg), .pdf, Calendar file (.ics)
        </option>
        <option value="Redirect">External Link</option>
        <option value="Survey">Form</option>
      </select>
    </div>

    <!-- Call to action file -->
    <div
      *ngIf="currentEditedCta.get('action.type').value === 'OpenFile'"
      class="form-group"
    >
      <div [formGroupName]="'action'">
        <span class="labelSectionPageEdition">File (Size Max. 2MB)</span>
        <div class="row m-0">
          <div class="col-md-6 px-0">
            <input
              id="cta_openfile_document"
              type="file"
              class="custom-file-input"
              accept="image/*, .pdf, .ics"
              [id]="'multi_cta_documentpath_' + currentEditedCtaIndex"
              (change)="onChangeFile($event, 'callToActionMultiCta')"
              formControlName="documentPath"
            />
            <label
              class="custom-file-label"
              for="'multi_cta_documentpath_' + currentEditedCtaIndex"
              >Upload</label
            >
            <div
              *ngIf="currentEditedCta.get('action.documentPath').invalid"
              class="alert alert-danger"
            >
              <div
                *ngIf="
                  currentEditedCta.get('action.documentPath').errors
                    .invalidFileSize
                "
              >
                This file is too large. It must be less than 2MB.
              </div>
              <div
                *ngIf="
                  currentEditedCta.get('action.documentPath').errors.extension
                "
              >
                File format not allowed. It must be an image, PDF or Calendar.
              </div>
              <div
                *ngIf="
                  currentEditedCta.get('action.documentPath').errors
                    .unauthorizedFileName
                "
              >
                File name not allowed. It must not contains any special
                characters other than "-" and "_".
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center">
            <p class="labelLike">
              {{
                currentEditedCta.get("action.documentPath").value ||
                  "No
                            file"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Call to action link -->
    <div
      *ngIf="currentEditedCta.get('action.type').value === 'Redirect'"
      class="mb-2"
    >
      <div [formGroupName]="'action'">
        <span class="labelSectionPageEdition">Exernal Link</span>
        <div class="input-group mb-3">
          <input
            type="text"
            id="url"
            class="form-control"
            formControlName="url"
            placeholder="Enter an external link"
            aria-describedby="external-link"
          />
        </div>
      </div>
    </div>

    <!-- Call to action survey -->
    <div
      *ngIf="currentEditedCta.get('action.type').value === 'Survey'"
      class="mb-2"
    >
      <div [formGroupName]="'action'">
        <span class="labelSectionPageEdition">Form</span>
        <select
          name="sel3"
          class="form-control"
          id="survey"
          formControlName="survey"
        >
          <option [value]="null" disabled>Select form</option>
          <option *ngFor="let SURVEY of SURVEYS_LIST" value="{{ SURVEY.UUID }}">
            Form - {{ SURVEY.TITLE }}
          </option>
        </select>
      </div>
    </div>

    <!-- Call to action page -->
    <div
      *ngIf="currentEditedCta.get('action.type').value === 'Internal'"
      class="mb-2"
    >
      <div [formGroupName]="'action'">
        <span class="labelSectionPageEdition">Internal Page</span>
        <div class="row">
          <select
            name="sel3"
            class="form-control"
            id="page"
            formControlName="page"
          >
            <option [value]="null" disabled>Select an internal page</option>
            <option
              *ngFor="let landing_page of landing_page_array"
              [value]="landing_page.value"
            >
              {{ landing_page.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<button
  type="button"
  class="btn btn-global-type btn-type2 d-flex ml-auto mt-2"
  [mat-dialog-close]="{ event: this.currentEditedCta }"
  cdkFocusInitial
>
  <span class="material-icons icons_button" style="width: 18px; height: 18px">
    save
  </span>
  <span class="ml-2">Save data</span>
</button>
